import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { entradasBlog } from '../../../helpers/configRoutes/backend_helper'

const BlogResumen = () => {

  const navigate = useNavigate();
  const [entradas, setEntradas] = useState([])

  useEffect(() => {
    const getEntradas = async () => {
      const response = await entradasBlog({ params: { perPage: 3 } });
      setEntradas(response.entradas)
    }
    getEntradas()
  }, []);


  return (
    <div className="flex flex-col px-12 pt-12 pb-10 rounded-2xl bg-stone-200 max-md:px-5 mb-2">
      <div className="flex gap-2.5 text-black max-md:flex-wrap text-start">
        <div className="flex-auto">
          <h2>Últimas entradas del Blog</h2>
        </div>
        <CustomButtonOval
          className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
          onClick={() => navigate('/blog')}
        >
          Ir al Blog
        </CustomButtonOval>
      </div>
      <div className="mt-6 max-md:max-w-full">
  <div className="flex gap-5 max-md:flex-col max-md:gap-0">
    {entradas.map((data, index) => {
      return (
        <div
          key={index}
          className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full p-4"
        >
          <div className="flex flex-col h-full justify-between text-sm leading-4 text-black max-md:mt-9">
            <div>
              <img
                loading="lazy"
                srcSet={data?.image}
                className="w-full aspect-[1.45]"
              />
              <div className="mt-8 text-2xl font-bold leading-8">
                {data?.title}
              </div>
              <div className="mt-3 font-bold whitespace-nowrap">
                {data?.author}
              </div>
              <div className="whitespace-nowrap">{data?.fecha}</div>
              <div className="mt-8 text-base leading-6">
                {data?.firstText}
              </div>
            </div>
            <div className="justify-center self-start mt-5">
              <CustomButtonOval
                className="bg-o2o-gris hover:bg-o2o-gris-hover"
                onClick={() => window.open(data.url, '_blank', 'noopener,noreferrer')}
              >
                Seguir leyendo
              </CustomButtonOval>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>

    </div>
  )
}

export default BlogResumen
"use client"
import { imgUrl } from '../../helpers/recursosurl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { scroller } from "react-scroll";

const HeaderPublico = () => {

  const menuList = [
    { label: "¿Quiénes somos?", ruta: '/quienes-somos' },
    { label: "Manifesto", ruta: '/manifesto' },
    { label: "¿Cómo funciona?", ruta: '/como-funciona' },
    { label: "Seguridad", ruta: '/seguridad' },
    { label: "Costos y membresías", ruta: '/costos-membresias' },
    { label: "Testimoniales", ruta: '/testimoniales' },
    { label: "blog", ruta: '/blog' },
    { label: "Iniciar sesión", ruta: '/login'},
  ];

  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    scroller.scrollTo("inicio", { duration: 0, delay: 0, smooth: true })
  }, [location])


  return (
    <>
      {location?.pathname !== '/login' && (
        <div className="flex flex-wrap justify-center item-center">
          <header className="bg-white p-2 flex flex-wrap justify-between items-center fixed top-0 w-full z-20 border-b-2 border-black">
            {/* Logo en el lado izquierdo */}
            <div className="flex items-center">
              <Link to="/">
                <img src={`${imgUrl}/Layer_1.png`} alt="Logo" className="h-8 md:h-16 ml-4 md:mr-2" />
              </Link>
              <span className="text-dark text-lg font-bold"></span>
            </div>
            <button
              className="md:hidden hover:bg-o2o-gris-arena rounded py-2 px-4 shadow-sm"
              onClick={() => setMenu(!menu)}
            >
              {menu ?
                <span className="material-symbols-outlined">
                  menu_open
                </span>
                : <span className="material-symbols-outlined">
                  menu
                </span>}
            </button>
            {/* Menú en el lado derecho */}
            <nav className="hidden md:block">
              <ul className="flex flex-col md:flex-row space-x-5 ">
                <li>
                  <button className="bg-white hover:bg-gray-200 text-gray-800 py-2 px-4 rounded-full border-o2o-aqua border-solid border-2">
                    Buscador por paciente o diagnóstico
                  </button>
                </li>
                <li>
                  <button
                    className="bg-white hover:bg-gray-200 text-gray-800  py-2 px-4 rounded-full border-o2o-aqua border-solid border-2"
                    onClick={() => navigate('/central-atencion')}
                  >
                    Central de atención
                  </button>
                </li>
                <li>
                  <button
                    className="bg-white hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded-full border-o2o-aqua border-solid border-2 flex items-center"
                    onClick={() => navigate('/login')}
                  >
                    <span className=''>Iniciar sesión</span>
                    {/* <img src="/user-image.jpg" alt="User" className="ml-2 h-6 w-6 rounded-full" /> */}
                  </button>
                </li>

              </ul>
              {/* Menu de escritorio */}
              <ul className="flex flex-col md:flex-row space-x-7 mt-2 md:mt-4">
                {menuList.map((data, index) => (
                  <li className='bg-white' key={index}>
                    <Link
                      className={`text-black ${location.pathname === data?.ruta ? 'font-bold' : ''}`}
                      to={data?.ruta}
                    >
                      {data?.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Menu responsivo */}
            {menu && (
              <nav className="w-full md:hidden opacity-transition">
                <ul className="flex flex-col mt-4">
                  {menuList.map((data, index) => (
                    <li className='bg-white' key={index}>
                      <Link
                        className="text-gray-800 hover:text-blue-600 hover:bg-o2o-gris-arena font-semibold p-2 flex"
                        to={data?.ruta}
                      >
                        {data?.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </header>
        </div>
      )}
    </>
  )
}

export default HeaderPublico;
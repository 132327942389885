"use client"
import React from 'react'
import Buscardor2 from '../home/Buscardor2'
import SlidesSteps from './SlidesSteps'
import PoliticaRespaldo from './PoliticaRespaldo'
import Garantias from './Garantias'
import { cardsStep1, cardsStep2, cardsStep3 } from '../../../helpers/recursos'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import Divider from '../../../components/Divider'

const Seguridad = () => {

  return (
    <>
      <Buscardor2 />
      <div className="flex flex-col items-center px-5">
        <div className="text-3xl font-bold leading-9 text-center text-black w-[808px] max-md:max-w-full">
          Te protegemos antes, durante y después de la experiencia
        </div>
        <div className="mt-5 text-base leading-6 text-center text-black w-[808px] max-md:max-w-full">
          Nuestros protocolos consideran todas las instancias en el proceso de
          contratación para{" "}
          <span className="font-bold">
            salvaguardar tu información, seguridad física, patrimonio y
            privacidad.
          </span>
        </div>
      </div>
      <div className="flex flex-col text-center text-black mr-[60px] ml-[60px]">
        <div className="flex gap-5 items-center mt-7 max-md:flex-wrap">
          <div className="justify-center items-center self-stretch px-3.5 text-2xl font-bold text-center text-black whitespace-nowrap bg-cyan-300 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
            1
          </div>
          <div className="flex-auto self-stretch my-auto text-2xl font-bold text-black max-md:max-w-full text-start">
            Antes del servicio
          </div>
        </div>
        <Divider />
      </div>
      {console.log(cardsStep1)}
      <div className="flex flex-col items-center">
        <div className="container">
          <div className="swiperContainer flex flex-row items-center">
            {cardsStep1.map((c, index) => (
              <div key={index} className="flex flex-col m-[12px] text-black max-w-[80%]">
                <img
                  loading="lazy"
                  srcSet={c?.img}
                  className="w-[457px] h-[300px] aspect-[1.52]"
                />
                <div className="mt-6 w-full text-2xl font-bold">{c?.titulo}</div>
                <div className="mt-3 w-full text-base leading-6">
                  {c?.descripcion}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col text-center text-black mr-[60px] ml-[60px]">
        <div className="flex gap-5 items-center mt-7 max-md:flex-wrap">
          <div className="justify-center items-center self-stretch px-3.5 text-2xl font-bold text-center text-black whitespace-nowrap bg-cyan-300 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
            2
          </div>
          <div className="flex-auto self-stretch my-auto text-2xl font-bold text-black max-md:max-w-full text-start">
            Durante del servicio
          </div>
        </div>
        <Divider />
      </div>

      <div className="flex flex-col items-center">
        <div className="container">
          <div className="swiperContainer flex flex-row items-center">
            {cardsStep2.map((c, index) => (
              <div key={index} className="flex flex-col m-[12px] text-black max-w-[80%]">
                <img
                  loading="lazy"
                  srcSet={c?.img}
                  className="w-[457px] h-[300px] aspect-[1.52]"
                />
                <div className="mt-6 w-full text-2xl font-bold">{c?.titulo}</div>
                <div className="mt-3 w-full text-base leading-6">
                  {c?.descripcion}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="flex flex-col text-center text-black mr-[60px] ml-[60px]">
        <div className="flex gap-5 items-center mt-7 max-md:flex-wrap">
          <div className="justify-center items-center self-stretch px-3.5 text-2xl font-bold text-center text-black whitespace-nowrap bg-cyan-300 rounded-2xl border-b border-solid border-black border-opacity-40 h-[42px] w-[42px]">
            3
          </div>
          <div className="flex-auto self-stretch my-auto text-2xl font-bold text-black max-md:max-w-full text-start">
            Despues del servicio
          </div>
        </div>
        <Divider />
      </div>

      <div className="flex flex-col items-center">
        <div className="container">
          <div className="swiperContainer flex flex-row items-center">
            {cardsStep3.map((c, index) => (
              <div key={index} className="flex flex-col m-[12px] text-black max-w-[80%]">
                <img
                  loading="lazy"
                  srcSet={c?.img}
                  className="w-[457px] h-[300px] aspect-[1.52]"
                />
                <div className="mt-6 w-full text-2xl font-bold">{c?.titulo}</div>
                <div className="mt-3 w-full text-base leading-6">
                  {c?.descripcion}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      

      <div className="flex flex-col px-5 justify-center items-center text-center mb-10 font mt-14">
        <div className="w-3/4 text-3xl leading-10 max-md:max-w-full max-md:text-4xl font-bold">
          ¿Aún tienes dudas?
        </div>
        <div className="mt-6 w-2/4 text-xl leading-8 max-md:max-w-full mb-5">
          Consulta el Centro de Atención, donde respondemos preguntas frecuentes y encontrarás los reglamentos de conducta y contratos de servicio.
        </div>
        <div className="flex w-full justify-center mb-10">
          <CustomButtonOval
            className="bg-o2o-violeta hover:bg-o2o-violeta-hover font-bold"
          /* onClick={() => navigate('/quienes-somos')} */
          >
            Ir al Centro de Atención
          </CustomButtonOval>
        </div>
      </div>

      <div className="flex flex-wrap justify-center text-start font mt-14 ml-[60px]">
        <div className='w-full sm:w-1/2 mb-5 '>
          <PoliticaRespaldo />
        </div>
        <div className='w-full sm:w-1/2 justify-center '>
          <Garantias />
        </div>
      </div>

    </>
  )
}



export default Seguridad
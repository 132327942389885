import React from 'react'

const CardImg = ({ imageUrl, title, content, tags }) => {
  return (
    <div className="rounded overflow-hidden shadow-lg">
      <img className="w-full" src={imageUrl} alt="Imagen del card" />
      {(title !== '' && content !== '') && (
      <div className="px-6 py-4 bg-o2o-gris-calido">
        <div className="font-bold text-xl mb-2">{title}</div>
        <div className="text-gray-700 text-base">{content}</div>
      </div>
      )}
    </div>
  )
}

export default CardImg
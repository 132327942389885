import axios from "axios"
import { del, get, post, postFile, put, postData} from "./api_helper"
import * as url from "./url_helper"
import {ToastHelper} from './alerts/alertHelper';
import { type } from "@testing-library/user-event/dist/type";
// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
        return null
}

const getAuthUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user).user
        return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}
//reset pasword
export const resetPassword = (data) =>post(url.RESET_PASSWORD, data)
// Login Method





export const postLoginToken = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
          throw response.data
    })
    .catch(err => {
        var message = ''
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404:
                    message = "Lo sentimos el recurso solicitado no existe"
                    break
                case 422:
                    message = err.response.data.message
                    break
                case 500:
                    message = "Lo sentimos! algo ha salido mal, por favor contacta a nuestro equipo de soporte."
                    break
                case 401:
                    message = "Credenciales inválidas"
                    break
                default:
                    message = err.response?.data?.message || err.message
                    break
            }
           ToastHelper(message,'error')
           return err.response.data
        } else {
            message = err.message
        }
        throw message
    })
}

// get Users

//Catalogos sin login
//export const resourcesFuelSales = (type,data={},id=null) => typeResource(url.RESOURCE_FUEL_SALES,type,data,id)
//ARchivos
/*
export const getHours = config => get(url.GET_HOURS, config)
export const getAsunto = config => get(url.GET_ASUNTO, config)
//Solicitudes de credito 
export const creditRequestsResource = (type,data={},id=null) => typeResource(url.RESOURCE_CREDITS_REQUESTS,type,data,id)
//Contactanos
export const contactResource = (type,data={},id=null) => typeResource(url.RESOURCE_CONTACTS,type,data,id)
//aliados
export const aliadoResource = (type,data={},id=null) => typeResource(url.RESOURCE_ALIADOS,type,data,id)
*/
//usuarios Enfermero y administrador resource
export const usuariosResource = (type,data={},id=null) => typeResource(url.USUARIOS_RESOURCE,type,data,id)
export const gestionarEmail = (data) => post(`${url.GESTIONAR_EMAIL}`,data)
export const gestionarCuenta = (data) => post(`${url.GESTIONAR_CUENTA}`,data)
export const usuarioCancelarCuenta = (data) => post(`${url.USUARIO_CANCELAR_CUENTA}`,data)

//Pacientes
export const pacientesResource = (type,data={},id=null) => typeResource(url.PACIENTES_RESOURCE,type,data,id)
export const pacienteDown = (data,id) => post(`${url.PACIENTE_DOWN}/${id}`,data)
//Solicitudes
export const serviciosResource = (type,data={},id=null) => typeResource(url.SERVICIO_RESOURCE,type,data,id)
export const getCotizacion = (data) => post(url.GET_COTIZACION,data);
export const accion_solicitud = (data) => post(url.ACCION_SOLICITUD,data);
export const declinar_solicitud = (data) => post(url.DECLINAR_SOLICITUD,data);
export const cancelar_servicio = (data) => post(url.CANCELAR_SERVICIO,data);
//Citas
export const cita_inicio = (data,id_serv,id_cita,token) => post(`${url.CITA_INICIO}/${id_serv}/${id_cita}/${token}`,data);
export const cita_fechas = (data) => post(`${url.CITA_FECHAS}`,data);
//Enfermeros
export const enfermeroResource = (type,data={},id=null) => typeResource(url.ENFERMERO_RESOURCE,type,data,id)
export const enfermeroTurnosResource = (type,data={},id=null) => typeResource(url.ENFERMERO_TURNOS_RESOURCE,type,data,id)
//faoritos
export const favoritos = (data) => post(url.FAVORITOS,data);
//calificaciones
export const calificacionesResource = (type,data={},id=null) => typeResource(url.CALIFICACIONES_RESOURCE,type,data,id)
//facturas
export const facturasResource = (type,data={},id=null) => typeResource(url.FACTURAS_RESOURCE,type,data,id)
export const bancariosResource = (type,data={},id=null) => typeResource(url.BANCARIOS_RESOURCE,type,data,id)
//Catalogos
export const paises = () => get(url.PAISES);
export const getSexosGeneros = () => get(url.SEXOS_GENEROS);
export const getCps = (config) => get(url.CPS,config);
export const getTiempos = () => get(url.GET_TIEMPOS);
export const estados = (config) => get(url.ESTADOS,config);
export const municipios = (config) => get(url.MUNICIPIOS,config);
export const getIntereses = (grupo) => get(`${url.GET_INTERESES}/${grupo}`);
export const getEspecialidades = (grupo) => get(`${url.GET_ESPECIALIDADES}`,grupo);
export const getCuidados = (grupo) => get(`${url.GET_CUIDADOS}`);
export const getEntidades = (grupo) => get(`${url.GET_ENTIDADES}`);
export const getHabilidades = (grupo) => get(`${url.GET_HABILIDADES}`);
export const getOfertaServicios = (grupo) => get(`${url.GET_OFERTA_SERVICIOS}`);
export const getMotivosCancelacion = (config) => get(url.GET_MOTIVOS_CANCELACION,config);
export const nivelesResource = (type,data={},id=null) => typeResource(url.NIVELES_RESOURCE,type,data,id)
export const getModulo = (config) => get(`${url.GET_MODULO}`,config);
//Registro
export const registro = (data) => post(url.REGISTRO,data);
//imagen dinamica
export const setImageResponse = (data) => post(url.SET_IMAGE_DINAMICA,data);
//Zendesk
export const entradasBlog = (data) => get(url.ENTRADAS_BLOG,data);
export const contratos = (data) => get(url.CONTRATOS,data);
//Conversaciones
export const conversacionesResource = (type,data={},id=null) => typeResource(url.CONVERSACIONES_RESOURCE,type,data,id)
//recompenzas
export const recompenzasResource = (type,data={},id=null) => typeResource(url.RECOMPENZAS_RESOURCE,type,data,id)

const typeResource = (route,type,data={},id=null) => {
    switch (type) {
    case 'get':
        return get(route,data);
        break
    case 'show':
        return get(`${route}/${id}`,data);
        break
    case 'post':
        return post(`${route}`,data);
        break
    case 'put':
        return put(`${route}/${id}`,data);
        break
    case 'del':
        return del(`${route}/${id}`);
        break
    case 'postFile':
        return postFile(`${route}`,data);
        break
    }

}

export {
    getLoggedInUser,
    isUserAuthenticated,
    getAuthUser
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}

"use client"
import { useNavigate } from 'react-router-dom'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { imgUrl } from '../../../helpers/recursosurl'
import React, { useState, useEffect } from 'react'

const Comunidad = () => {
    const navigate = useNavigate();
    const images = [
        { id: 1, url: `${imgUrl}/GroupMask.png` },
        { id: 2, url: `${imgUrl}/carrusel1.png` },
        { id: 3, url: `${imgUrl}/carrusel2.png` },
        { id: 4, url: `${imgUrl}/carrusel3.png` },
        { id: 5, url: `${imgUrl}/carrusel4.png` },
        { id: 6, url: `${imgUrl}/carrusel5.png` },
        { id: 7, url: `${imgUrl}/carrusel6.png` },
    ];

    const [data, setData] = useState({
        currentImageIndex: 0,
        interval: null,
        transitionDurationTime: 1,
        slideDurationTime: 4500
    })

    const changeImg = (index) => {
        setData({
            ...data,
            currentImageIndex: index
        });
        //runSlide();
    }


    const next = () => {
        if (data.currentImageIndex >= images.length - 1) {
            data.currentImageIndex = 0
        } else {
            data.currentImageIndex += 1
        }
        //runSlide()
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            next();
            changeImg(data.currentImageIndex)
        }, 4000);
        return () => clearInterval(intervalId);
    }, []);

    const runSlide = () => {
        console.log('runslide')
        setData({
            ...data,
            interval: clearInterval(data.interval)
        });
        setData({
            ...data,
            interval: setInterval(() => next(), data.slideDurationTime)
        });
    }

    return (
        <>
            <div className="shadow-md flex flex-col justify-center text-black w-full max-w-o2o mt-[70px]">
                <div className="lg:w-full text-center">
                    <div className="rounded overflow-hidden shadow-sm">
                        <div
                            className="flex w-full flex-row transition ease-out duration-700 h-500"
                            style={{ transform: `translateX(-${data.currentImageIndex * 100}%)` }}
                            data-carousel-item
                        >
                            {
                                images.map((img, index) => (
                                    <img key={index} src={img.url} className="object-fit h-auto max-w-7xl" alt="img" />
                                ))
                            }
                        </div>
                        <div className="px-6 py-4 bg-o2o-gris-calido">
                            <div className="flex w-full flex-row justify-center space-x-1 mt-[42px]">
                                {
                                    images.map((img, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`w-[6px] h-[6px] rounded-full ${data.currentImageIndex === index ? 'bg-o2o-aqua' : 'bg-black'}`}
                                            onClick={() => changeImg(index)}
                                        />
                                    ))
                                }
                            </div>
                            <div className="text-gray-700 text-base mt-[18px] ">
                                <div className='mx-auto text-black max-w-[832px] ml-5 md:ml-0'>
                                    <div className="flex justify-center mb-5">
                                        <h2>Somos una comunidad de expertos con alto valor humano</h2>
                                    </div>
                                    <div className="flex justify-center mb-5">
                                        <p className="text-black">
                                            Nuestra plataforma <strong>elimina la incertidumbre del servicio a domicilio de enfermería</strong> y permite que pacientes y enfermeros se encuentren en lo profesional y lo humano. <strong>Uno a uno</strong>.
                                        </p>
                                    </div>
                                    <div className="flex justify-center mb-6">
                                        <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3">
                                            <CustomButtonOval
                                                className="bg-o2o-gris-arena hover:bg-o2o-gris-hover"
                                                onClick={() => navigate('/manifesto')}
                                            >
                                                <p className='text-sm font-bold'>Lee en manifesto de One 2 One Nurses</p>
                                            </CustomButtonOval>
                                            <CustomButtonOval
                                                className="bg-o2o-gris-arena hover:bg-o2o-gris-hover"
                                            >
                                                <p className='text-sm font-bold'>¿Dudas? Lee las FAQs</p>
                                            </CustomButtonOval>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Comunidad
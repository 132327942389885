import React from 'react'
import { imgUrl } from '../../../helpers/recursosurl'

const HagamosEquipo = () => {
    return (
        <div className="flex flex-wrap justify-center mt-5 lg:mt-10 md:mt-20 mb-[18px]">
            <div className="lg:w-full text-center mb-[18px]"> 
                <h1 className="text-black">
                Enfermer@, hagamos equipo
                </h1>
            </div>
            <div className="max-w-[820px] w-full mx-auto text-center"> 
                <p className="text-center text-base">
                Nuestra misión es ofrecer <b>oportunidades laborales seguras y justas</b> que permitan autonomía 
                e independencia profesional. 
                Al trabajar en One to One Nurses, puedes elegir libremente tus horarios y clientes.
                </p>
            </div>
            <div className="flex flex-wrap justify-center mt-5 lg:mt-[3rem] md:mt-10 w-full max-w-o2o">
                <div className="w-full text-center mb-3 "> 
                    <img className="w-full" src={`${imgUrl}/registrare-enf.png`} alt="Imagen del card" />
                </div>
            </div>
        </div>
        
    )
}

export default HagamosEquipo
"use client"
import { imgUrl } from '../../../helpers/recursosurl'
import React from 'react'
import SliderHome from './SliderHome'

const ComentariosBlog = () => {
    return (
        <>
            <div className='flex flex-col w-full h-screen max-h-screen relative overflow-hidden'>
                <div className='max-w-o2o w-full mx-auto'>
                    <img src={`${imgUrl}/Group_136.png`} alt="Imagen" className="w-full h-screen max-h-screen mx-auto" />
                </div>
                <SliderHome />
            </div>
        </>
    )
}

export default ComentariosBlog
import React from "react";
import QRCode from "qrcode.react";
import { setImageResponse } from "../helpers/configRoutes/backend_helper";
import CustomButtonOval from "./buttons/CustomButtonOval";

const QrServicios = ({ value }) => {
    const qrRef = React.useRef();

    const handleShare = async () => {
        const canvas = qrRef.current.querySelector('canvas');
        const originalWidth = canvas.width;
        const originalHeight = canvas.height;

        // Establece el nuevo tamaño del canvas para ser más grande
        const newWidth = originalWidth * 2; // Ajusta el tamaño según sea necesario
        const newHeight = originalHeight * 2;

        // Crea un nuevo canvas y ajusta el contenido
        const newCanvas = document.createElement('canvas');
        newCanvas.width = newWidth;
        newCanvas.height = newHeight;
        const ctx = newCanvas.getContext('2d');

        // Ajusta la escala para redibujar el contenido sin distorsión
        ctx.scale(newWidth / originalWidth, newHeight / originalHeight);
        ctx.drawImage(canvas, 0, 0);

        // Convierte el canvas redimensionado a una URL de datos
        const imageUrl = newCanvas.toDataURL('image/png');

        // Envía la URL para su uso posterior
        const imageResponse = await setImageResponse({ image_dinamica: imageUrl });

        if (imageResponse.status) {
            const message = encodeURIComponent(`Mira este QR: ${imageResponse.url}`);
            const whatsappUrl = `https://wa.me/?text=${message}`;
            window.open(whatsappUrl, '_blank');
        }
    };

    return (
        <>
            <div className="flex justify-center items-center">
                <div className="p-4 rounded-lg" ref={qrRef}>
                    <QRCode
                        value={value}
                        size={108} // Tamaño del código QR
                        bgColor="#ffffff"
                        fgColor="#000000"
                        className="" // Clases responsivas
                    />
                </div>
            </div>
            <div className="flex justify-center items-center mt-4">
                <CustomButtonOval
                    onClick={() => handleShare()}
                    className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                >
                    Compartir en WhatsApp
                </CustomButtonOval>

            </div>
        </>
    );
};

export default QrServicios;
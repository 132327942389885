import React, { useEffect, useState } from "react";
import Divider from "../../components/Divider";
import CustomInput from "../../components/inputs/CustomInput";
import { imgUrl } from "../../helpers/recursosurl";
import FileInput from "../../components/inputs/FileInput";
import CustomSelect from "../../components/inputs/CustomSelect";
import DireccionDatos from "../../components/registro/DireccionDatos";
import CustomTextArea from "../../components/inputs/CustomTextArea";
import CustomInputMask from "../../components/inputs/CustomInputMask";
import CustomInputMaskPerson from "../../components/inputs/CustomInputMaskPerson";
import { getSexosGeneros } from "../../helpers/configRoutes/backend_helper";


const InformacionPersonal = ({ validation }) => {
    const c_estadoCivil = [
        { value: 'Soltero', name: 'Soltero' },
        { value: 'Casado', name: 'Casado' },
        { value: 'Divorciado', name: 'Divorciado' },
        { value: 'Viudo', name: 'Viudo' }
    ]

    const handleFileChange = async (archivo, name) => {
        try {
            const base64 = await getBase64(archivo);
            const formato = archivo.type
            validation.setFieldValue(`${name}_formato`, formato)
            validation.setFieldValue(name, base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const [sexos, setSexos] = useState([])
    useEffect(() => {
        const getSexos = async () => {
            const response = await getSexosGeneros();
            setSexos(response.sexos)
        }
        getSexos()
    }, []);

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] lg:ml-[19%] sm:ml-[10%]  mt-[40px]">                
                {validation.values.perfil_id == 4 &&
                    <div className="w-full text-2xl font-bold text-black">
                        1. Informacion General
                    </div>
                }

                <div className="w-full px-2 mb-3 ">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[18%] max-md:ml-0 max-md:w-full ">
                            <img
                                loading="lazy"
                                srcSet={validation.values.perfilImg || `${imgUrl}/user.png`}
                                className="shrink-0 max-w-full aspect-square  w-[163px] max-md:mt-10 rounded-[75px]"
                            />
                            <span className="text-red-600">
                                {validation.errors?.perfilImg ? 'Error en la imagen' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col ml-[469px] w-[52%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow px-5 mt-20 text-sm text-black max-md:mt-10 max-md:max-w-full">
                                <div className="justify-center self-start">
                                    <FileInput
                                        format={'img'}
                                        onChange={(e) => handleFileChange(e, 'perfilImg')}
                                    />
                                </div>
                                <div className="mt-3 tex-sm leading-5 max-md:max-w-full">
                                    La fotografía de perfil deberá mostrar a la persona desde el
                                    pecho con el rostro descubierto y viendo al frente. El fondo
                                    deberá ser de preferencia claro y liso.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 w-full text-base font-bold text-black  max-md:max-w-full">
                    Información Personal
                </div>
                <div className='w-full'>
                    <Divider />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Nombre(s)
                    </div>
                    <CustomInput
                        name="nombre"
                        value={validation.values.nombre || ''}
                        error={validation.errors?.nombre}
                        placeholder={'Nombre(s)'}
                        type={'text'}
                        readonly={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Apellidos
                    </div>
                    <CustomInput
                        name="apellidos"
                        value={validation.values.apellidos || ''}
                        error={validation.errors?.apellidos}
                        placeholder={'Apellidos'}
                        type={'text'}
                        readonly={true}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Teléfono
                    </div>
                    <CustomInput
                        name="telefono"
                        placeholder={'1234567890'}
                        value={validation.values.telefono || ''}
                        error={validation.errors?.telefono}
                        type={'text'}
                        readonly={true}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Móvil
                    </div>
                    <CustomInput
                        name="movil"
                        placeholder={'1234567890'}
                        value={validation.values.movil || ''}
                        error={validation.errors?.movil}
                        type={'text'}
                        readonly={true}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        CURP
                    </div>
                    <CustomInput
                        name="curp"
                        value={validation.values.curp || ''}
                        error={validation.errors?.curp}
                        placeholder={'LEOA8503116XX'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Correo Electronico
                    </div>
                    <CustomInput
                        name="email"
                        placeholder={'example@mail.com'}
                        value={validation.values.email || ''}
                        error={validation.errors?.email}
                        type={'email'}
                        readonly={true}
                        onBlur={validation.handleBlur}
                    />

                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Estado civil
                    </div>
                    <div className="justify-center ">
                        <CustomSelect
                            name="estadoCivil"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...c_estadoCivil?.map(tipo => {
                                    return { value: tipo?.name, label: tipo?.name }
                                })
                            ]}
                            value={validation.values.estadoCivil || ''}
                            error={validation.errors?.estadoCivil}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Fecha de naciemiento
                    </div>
                    <CustomInput
                        name="fechaNacimiento"
                        value={validation.values.fechaNacimiento || ''}
                        error={validation.errors?.fechaNacimiento}
                        type={'date'}
                        readonly={true}s
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Sexo
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="sexo_id"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...sexos?.map(tipo => {
                                    return { value: tipo?.id, label: tipo?.nombre }
                                })
                            ]}
                            value={validation.values.sexo_id || ''}
                            error={validation.errors?.sexo_id}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <DireccionDatos validation={validation} handleFileChange={handleFileChange} />
                <div className="mt-3 w-full text-base font-bold leading-6 text-black max-md:max-w-full border-b border-black border-solid  ">
                    Acerca de ti
                </div>
                <div className='w-full border-t border-black border-solid mb-3'></div>

                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Descripción personal
                    </div>
                    <div className="justify-center ">
                        <CustomTextArea
                            value={validation.values.descripcionPersonal || ''}
                            error={validation.errors?.descripcionPersonal}
                            rows={6}
                            placeholder='Por ejemplo: Soy una persona muy ordenada y estudiosa; también disfruto del deporte y del buen cine.'
                            name="descripcionPersonal"
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Información que te hace único
                    </div>
                    <div className="justify-center ">
                        <CustomTextArea
                            rows={6}
                            placeholder='Por ejemplo: Me gusta la naturaleza y me encanta ver los amaneceres.'
                            name="informacionUnica"
                            value={validation.values.informacionUnica || ''}
                            error={validation.errors?.informacionUnica}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InformacionPersonal;
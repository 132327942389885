import React, { useEffect, useState } from "react";
import { imgUrl } from "../../../helpers/recursosurl";
import FileInput from "../../inputs/FileInput";
import CustomInput from '../../inputs/CustomInput'
import CustomSelect from '../../inputs/CustomSelect'
import CustomTextArea from '../../inputs/CustomTextArea'
import Divider from "../../Divider";
import DireccionDatos from "../DireccionDatos";
import CustomInputMaskPerson from "../../../components/inputs/CustomInputMaskPerson";
import { getSexosGeneros } from "../../../helpers/configRoutes/backend_helper";

const InformacionPaciente = ({ validation }) => {

    const c_estadoCivil = [
        { value: 'Soltero', name: 'Soltero' },
        { value: 'Casado', name: 'Casado' },
        { value: 'Divorciado', name: 'Divorciado' },
        { value: 'Viudo', name: 'Viudo' }
    ]

    const handleFileChange = async (archivo, name) => {
        try {
            const base64 = await getBase64(archivo);
            const formato = archivo.type
            validation.setFieldValue(`${name}_formato`, formato)
            validation.setFieldValue(name, base64)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const [sexos, setSexos] = useState([])
    useEffect(() => {
        const getSexos = async () => {
            const response = await getSexosGeneros();
            setSexos(response.sexos)
        }
        getSexos()
    }, []);

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="w-full text-2xl font-bold text-black">
                    Perfil Paciente
                </div>

                <div className="w-full px-2 mb-3 border-t border-black border-solid">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[18%] max-md:w-full max-md:mb-4">
                            <img
                                loading="lazy"
                                srcSet={validation.values.perfilImg || `${imgUrl}/user.png`}
                                className="shrink-0 max-w-full aspect-square w-[156px] max-md:w-full max-md:mt-10"
                            />
                            <span className="text-red-600">
                                {validation.errors?.perfilImg ? 'Error en la imagen' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col w-[82%] max-md:w-full">
                            <div className="flex flex-col grow px-5 mt-20 text-sm text-black max-md:mt-10 max-md:max-w-full">
                                <div className="justify-center self-start">
                                    <FileInput
                                        format={'img'}
                                        onChange={(e) => handleFileChange(e, 'perfilImg')}
                                    />
                                </div>
                                <div className="mt-3 leading-5 max-md:max-w-full">
                                    La fotografía de perfil deberá mostrar a la persona desde el
                                    pecho con el rostro descubierto y viendo al frente. El fondo
                                    deberá ser de preferencia claro y liso.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-12 w-full text-base font-bold text-black max-md:text-lg">
                    Información de la cuenta
                </div>

                <div className='w-full'>
                    <Divider />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Nombre(s)
                    </div>
                    <CustomInput
                        name="nombre"
                        value={validation.values.nombre || ''}
                        error={validation.errors?.nombre}
                        placeholder={'Nombre(s)'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Apellidos
                    </div>
                    <CustomInput
                        name="apellidos"
                        value={validation.values.apellidos || ''}
                        error={validation.errors?.apellidos}
                        placeholder={'Apellidos'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Teléfono
                    </div>
                    <CustomInputMaskPerson
                        name="telefono"
                        mask={"9999999999"}
                        value={validation.values.telefono || ''}
                        error={validation.errors?.telefono}
                        type={'number'}
                        placeholder={'1234567890'}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        CURP
                    </div>
                    <CustomInput
                        name="curp"
                        value={validation.values.curp || ''}
                        error={validation.errors?.curp}
                        placeholder={'LEOA8503116XX'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Correo Electrónico
                    </div>
                    <CustomInput
                        name="email"
                        placeholder={'example@mail.com'}
                        value={validation.values.email || ''}
                        error={validation.errors?.email}
                        type={'email'}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Estado civil
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="estadoCivil"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...c_estadoCivil?.map(tipo => ({ value: tipo?.name, label: tipo?.name }))
                            ]}
                            value={validation.values.estadoCivil || ''}
                            error={validation.errors?.estadoCivil}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Fecha de nacimiento
                    </div>
                    <CustomInput
                        name="fechaNacimiento"
                        value={validation.values.fechaNacimiento || ''}
                        error={validation.errors?.fechaNacimiento}
                        type={'date'}
                        onChange={validation.handleChange}
                    />
                </div>

                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Sexo
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="sexo_id"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...sexos?.map(tipo => ({ value: tipo?.id, label: tipo?.nombre }))
                            ]}
                            value={validation.values.sexo_id || ''}
                            error={validation.errors?.sexo_id}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>

                <DireccionDatos validation={validation} handleFileChange={handleFileChange} />
            </div>

        </>
    )
}

export default InformacionPaciente;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Asegúrate de importar estos hooks de react-router-dom
import { getAuthUser } from "../../helpers/configRoutes/backend_helper";
import { useSelector } from "react-redux";

const Menu = () => {
    const authUser = getAuthUser()
    const [menu, setMenu] = useState(false);
    const location = useLocation(); // Para obtener la ubicación actual
    const imgUrl = "path_to_images"; // Asegúrate de reemplazar con la ruta correcta a tus imágenes
    const sinLeer = useSelector((state) => state.mensajesContador.sin_leer);


    const menuList = [
        { label: "Mi resumen", ruta: "/home" },
        { label: "Mi perfil", ruta: "/perfiles", perfil_id: 4 },
        { label: "Turnos", ruta: "/turnos", perfil_id: 4 },
        { label: "Conversaciones", ruta: "/mensajes", perfil_id: 4, addNotificacion: sinLeer },
        { label: "Mis servicios", ruta: "/servicios", perfil_id: 4 },
        { label: "Mis honorarios", ruta: "/honorarios", perfil_id: 4 },
        //enfermeroMenu
        { label: "Mis perfiles", ruta: "/perfiles", perfil_id: 5 },
        { label: "Conversaciones", ruta: "/mensajes", perfil_id: 5, addNotificacion: sinLeer },
        { label: "Historial de servicios", ruta: "/servicios", perfil_id: 5 },
        { label: "Informacion de cobro", ruta: "/informacion-cobro", perfil_id: 5 },
    ];

    return (
        <div className="flex ">
            {/* Menú lateral más ancho */}
            <div className={`fixed top-[82px] left-0 h-full z-20 bg-transparent transition-transform transform ${menu ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 w-60 md:w-62`}>
                <div className="p-4">
                    <h2 className="text-lg md:text-xl font-semibold">Hola, {authUser.nombre}</h2>
                    <button
                        className="md:hidden hover:bg-o2o-gris-arena rounded py-2 px-4 shadow-sm mb-4"
                        onClick={() => setMenu(!menu)}
                    >
                        {menu ?
                            <span className="material-symbols-outlined">
                                menu_open
                            </span>
                            : <span className="material-symbols-outlined">
                                menu
                            </span>
                        }
                    </button>
                    <nav className="mt-10 md:mt-16">
                        <ul className="space-y-4">
                            {menuList.map((data, index) => {
                                if (data.perfil_id == authUser.perfil_id || data.perfil_id == undefined) {
                                    return (
                                        <li className="bg-transparent flex justify-between items-center" key={index}>
                                            <Link
                                                className={`text-black ${location.pathname === data.ruta ? 'font-bold' : ''}`}
                                                to={data.ruta}
                                            >
                                                {data.label}
                                            </Link>
                                            {data.addNotificacion !== undefined &&
                                                <div className="text-base bg-o2o-aqua rounded-lg p-1 pl-3 pr-3 mr-10">
                                                    {sinLeer}
                                                </div>
                                            }
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                        {authUser.perfil_id == 4 &&
                            <>
                                <div className="relative flex mt-10 md:mt-16">
                                    <button className={`text-sm relative ${authUser.solicitud.estatus_id == 1 ? 'z-10' : ''} px-6 py-2 ml-1 text-white bg-teal-400 rounded-full`}>Online</button>
                                    <button className={`text-sm absolute ${authUser.solicitud.estatus_id != 1 ? 'z-10' : ''} left-0 px-6 py-2 ml-20 text-gray-700 bg-gray-300 rounded-full`}>Offline</button>
                                </div>
                                <p className="text-sm md:text-base mt-2 md:mt-4">{authUser.solicitud.estatus_id == 4 ? 'Tu cuenta está en proceso de validación' : (authUser.solicitud.estatus_id == 1 ? 'En línea' : 'Fuera de línea')}</p>
                            </>
                        }
                    </nav>
                </div>
            </div>


            {/* Contenido principal ajustado */}
            <div className="flex-1 ml-0 md:ml-80">
                <header className="bg-white p-2 flex justify-between items-center fixed top-0 w-full z-10 border-b-2 border-black">
                    {/* Botón de menú para pantallas pequeñas */}
                    <button
                        className="md:hidden hover:bg-o2o-gris-arena rounded py-2 px-4 shadow-sm"
                        onClick={() => setMenu(!menu)}
                    >
                        {menu ?
                            <span className="material-symbols-outlined">
                                menu_open
                            </span>
                            : <span className="material-symbols-outlined">
                                menu
                            </span>}
                    </button>
                    {/* Logo en el lado izquierdo */}
                    <div className="flex items-center">
                        <Link to="/">
                            <img src={`${imgUrl}/Layer_1.png`} alt="Logo" className="h-8 md:h-16 ml-4 md:mr-2" />
                        </Link>
                        <span className="text-dark text-lg font-bold">Mi Aplicación</span>
                    </div>
                </header>

                {/* Resto del contenido */}
                <main className="p-4 mt-16">
                    {/* Aquí va el contenido de tu página */}
                </main>
            </div>
        </div>
    )
}

export default Menu;

import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import FileInput from "../../components/inputs/FileInput";
import { useNavigate, useParams } from "react-router-dom";
import { serviciosResource,facturasResource } from "../../helpers/configRoutes/backend_helper";
import { imgUrl } from "../../helpers/recursosurl";
import StarRating from "../../components/CalificacionEstrellas";
import { warningToast } from "../../helpers/Toast";

const FacturasEnfermero = ({ }) => {
    const { id_servicio } = useParams()
    const [facturaData, setFacturaData] = useState({ nameFile_pdf: '', nameFile_xml: '', nombre_pdf: '', type_pdf: '', servicio_id: id_servicio, file_pdf: '', nombre_xml: '', type_xml: '', file_xml: '' })
    const [servicio, setServicio] = useState({})
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('show', {}, id_servicio);
            setServicio(response.servicio);
        }
        console.log(id_servicio, 'idser')
        if (id_servicio) {
            getServicio();
        }
    }, []);


    const handleFileChange = async (archivo, name) => {
        try {
            const base64 = await getBase64(archivo);
            const formato = archivo.type
            const nameFile = archivo.name


            setFacturaData({ ...facturaData, [`nameFile_${name}`]: nameFile, [`nombre_${name}`]: name, [`type_${name}`]: formato, servicio_id: id_servicio, [`file_${name}`]: base64 })
            console.log(facturaData, 'objetArchivo')
            //validation.setFieldValue(name, base64)
            //validation.setFieldValue(`${name}_formato`, formato)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const enviarFactura= async()=>{
        setLoading(true)
        if(!facturaData.nameFile_pdf || !facturaData.nameFile_xml){
            warningToast('Sube tu pdf y xml con el formato correcto');
            setLoading(false)
            return false;
        }
        const response = await facturasResource('post', facturaData);
        if(response.status){
            setLoading(false)
            navigate('/servicios')
        }
        setLoading(false)
    } 
    return (
        <>
            <div className="flex flex-wrap max-w-[75%] ml-[19%] mr-full">
                <div className="w-full flex flex-col items-start">
                    <div className="w-full md:w-4/5">
                        <div className="flex justify-between items-center mb-[18px]">
                            <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" >
                                <svg
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.80078 1L1.00078 5L5.80078 9"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                &nbsp;&nbsp;Regresar a solicitudes de servicio
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
                <div className="text-[24px] font-semibold mb-[34px]">
                    <h3>Facturación</h3>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-4">
                        <div className="text-base font-semibold">
                            Servicio de {servicio.administrador?.nombre} {servicio.administrador?.apellidos} para:
                        </div>
                        <p>{servicio?.folio}</p>
                        <CustomButtonOval className="bg-o2o-secondary-rojo text-sm">Reportar</CustomButtonOval>
                    </div>
                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row items-center justify-between">
                        <div className="flex items-center">
                            <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5">
                                <img
                                    loading="lazy"
                                    srcSet={servicio.paciente?.perfilImg || `${imgUrl}/user.png`}
                                    className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                                />
                            </div>
                            <div className="flex flex-col lg:flex-row gap-6">
                                <div className="flex flex-col ">
                                    <div className="text-sm font-semibold flex flex-row items-center space-x-2 mb-1">
                                        <p className="mr-2">{servicio.paciente?.nombre} {servicio.paciente?.apellidos}</p>
                                        <p>{servicio.paciente?.edad} años</p>
                                    </div>
                                    <div className="text-sm">
                                        {servicio.paciente?.diagnosticoM}
                                    </div>
                                    <StarRating rating={servicio.paciente?.calificacion} color={'#FFA000'} estatico={true} />
                                    <CustomButtonOval className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" >Ver perfil completo</CustomButtonOval>
                                </div>
                                <div className="lg:w-1/3 flex flex-col items-center lg:items-start">
                                    <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena">
                                        <div className="text-sm font-semibold">Periodo del Servicio</div>
                                        <div className="text-sm">
                                            <p>{servicio.fecha_inicial} al {servicio.fecha_fin}</p>
                                            <p>
                                                {servicio?.dias_names?.map((d, index) => (
                                                    <span key={index}>{d.dia}, </span>
                                                ))}
                                            </p>
                                            <p>Turno {servicio.tiempo?.nombre} {servicio.turno?.nombre}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:w-1/3 flex flex-col items-center lg:items-start">
                                    <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena">
                                        <div className="text-sm font-semibold">Contacto</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <div className="text-[24px] font-semibold">
                        <h3>Sube tu recibo de honorarios de turnos concluidos  ${servicio.montoAfacturar} <span className="text-sm">Monto en el recibo</span></h3>
                    </div>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <div className="text-base font-semibold">
                        Datos para facturación
                    </div>
                    <hr className="border-black my-4" />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
                        <div>
                        <p>Utiliza estos datos para realizar tu recibo de honorarios. Recuerda que el concepto es: «servicios médicos y enfermería».</p>
                            <div className="flex mt-[18px] gap-[24px]">
                                <p className="font-semibold">Nombre:</p>
                                <p>Proveedora de Productos y Servicios Roxima Sa de CV</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">RFC</p>
                                    <p>LEOA123456AA7</p>
                                </div>
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Email</p>
                                    <p>facturacion@domain.com</p>
                                </div>

                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Calle</p>
                                    <p>Privada de la 23 sur</p>
                                </div>
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Colonia</p>
                                    <p>Ex-Hacienda La Noria</p>
                                </div>

                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Número exterior</p>
                                    <p>3701</p>
                                </div>
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Número interior</p>
                                    <p>B-103</p>
                                </div>

                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Código postal</p>
                                    <p>72410</p>
                                </div>
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Municipio</p>
                                    <p>Puebla</p>
                                </div>

                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">Estado</p>
                                    <p>Puebla</p>
                                </div>
                                <div className="flex mt-[12px] gap-[24px]">
                                    <p className="font-semibold">País</p>
                                    <p>México</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="mt-6">
                        <div className="text-base font-semibold">
                            Documentos
                        </div>
                        <hr className="border-black my-4" />
                        <div className="text-sm font-semibold gap-[24px]">
                            Archivo  {facturaData.nameFile_pdf}  {facturaData.nameFile_xml}
                        </div>
                    </div>
                    <div className="flex space-x-4 mt-4">
                        <FileInput
                            textButton={false}
                            nameInput={'Subir pdf'}
                            withClass={'w-[15%]'}
                            format={'pdf'}
                            onChange={(e) => handleFileChange(e, 'pdf')}
                        ></FileInput>
                        <FileInput
                            textButton={false}
                            nameInput={'Subir XML'}
                            withClass={'w-[15%]'}
                            format={'xml'}
                            onChange={(e) => handleFileChange(e, 'xml')}
                        ></FileInput>
                    </div>
                </div>
                <div className="flex justify-center border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <CustomButtonOval textCarga={'Enviando factura'} loading={loading}  onClick={()=>enviarFactura()} className="flex items-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover" >Enviar factura</CustomButtonOval>
                </div>
            </div>
        </>
    );
}

export default FacturasEnfermero;
import React, { useEffect, useState } from "react";
import CustomInput from "../../inputs/CustomInput";
import CustomSelect from "../../inputs/CustomSelect";
import CustomTextArea from "../../inputs/CustomTextArea";
import { nivelesResource } from "../../../helpers/configRoutes/backend_helper";

const FormacionAcademica = ({ validation, update }) => {

    const [estudios,setEstudios] = useState([]);
    useEffect(() => {
        const getNiveles = async () => {
            const response = await nivelesResource('get')
            setEstudios(response.niveles)
        }
        getNiveles()
    }, []);
    /*[
        { id: 'Licenciatura', nombre: 'Licenciatura' },
        { id: 'Técnico', nombre: 'Técnico' },
    ];*/

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:max-w-full max-md:ml-0">
                <div className="w-full text-black">
                    {update ? (
                        <>
                            <p className="text-xl mb-10 font-bold">2. Formación & experiencia laboral</p>
                            <div className="flex flex-col items-start">
                                <div className="justify-center items-center self-start w-6 h-6 text-base font-bold leading-6 text-center text-white whitespace-nowrap bg-rose-600 rounded-2xl shadow-sm">
                                    !
                                </div>
                                <p className="text-sm text-gray-800 mt-2">
                                    Mentir sobre el diagnóstico del paciente para obtener cuidados enfermeros de niveles más accesibles, no sólo pone en riesgo la salud del paciente; sino infringe los términos del contrato con One 2 One Nurses.
                                </p>
                            </div>
                        </>
                    ) : (
                        <p className="text-xl font-bold">Formación Académica</p>
                    )}
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                {update && (
                    <>
                        <p className="text-base mb-6 font-bold mt-10">Nivel académico</p>
                        <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                    </>
                )}
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Estudios
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="estudios"
                            options={[
                                { label: 'Seleccione una opción.', value: 0 },
                                ...estudios.map(tipo => ({ value: tipo?.id, label: tipo?.nombre }))
                            ]}
                            value={validation.values.estudios || ''}
                            error={validation.errors?.estudios}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Casa de estudios
                    </div>
                    <CustomInput
                        name="casa_estudios"
                        value={validation.values.casa_estudios || ''}
                        error={validation.errors?.casa_estudios}
                        placeholder="Casa de estudios"
                        type="text"
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Cédula Profesional
                    </div>
                    <CustomInput
                        name="cedula_profesional"
                        value={validation.values.cedula_profesional || ''}
                        error={validation.errors?.cedula_profesional}
                        type="text"
                        placeholder="12345678"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Año de graduación
                    </div>
                    <CustomInput
                        name="graduacion_fecha"
                        value={validation.values.graduacion_fecha || ''}
                        error={validation.errors?.graduacion_fecha}
                        type="date"
                        onChange={validation.handleChange}
                    />
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Descriptivo profesional
                    </div>
                    <div className="justify-center">
                        <CustomTextArea
                            value={validation.values.descripcion_profesional || ''}
                            error={validation.errors?.descripcion_profesional}
                            rows={6}
                            placeholder="Por ejemplo: Soy una enfermera con más de 10 años de experiencia en cuidados intensivos y emergencias. Mi enfoque es proporcionar atención de alta calidad y apoyo emocional a los pacientes y sus familias. Mantengo la calma bajo presión, tomo decisiones rápidas y me actualizo constantemente para brindar la mejor atención posible."
                            name="descripcion_profesional"
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
            </div>


        </>
    )
}
export default FormacionAcademica
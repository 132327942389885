import React from 'react'
import Buscador from './Buscador';
import InfoPerfil from './InfoPerfil';
import Comunidad from './Comunidad';
import Protocolos from './Protocolos';
import ComentariosBlog from './ComentariosBlog';
import HeadHunters from './HeadHunters';
import QuienesSomos from './QuienesSomos';
import HagamosEquipo from './HagamosEquipo';
import RegistrateCliente from './RegistrateCliente';

const Home = () => {

  return (
    <>
    <div className="flex flex-col justify-center items-center mx-auto w-full">
      <Buscador/>
      {/* COMO CREAR PERFIL INFO */}
      <div className='mt-10'>
      <RegistrateCliente/>
      </div>
      <InfoPerfil/>
      {/* COMUNIDAD */}
      <Comunidad/>
      {/* PROTOCOLOS */}
      <Protocolos/>
      {/* Comentarios del Blog */}
      <ComentariosBlog/>
      {/* HEadHunter */}
      <HeadHunters/>
      {/* Quienes somos */}
      <QuienesSomos/>
      {/* Hagamos Equipo */}
      <HagamosEquipo/>

      <RegistrateCliente/>
      </div>
    </>
  );
}

export default Home;

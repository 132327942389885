import React from "react";
import MisPerfiles from "../administrador/mis_perfiles/MisPerfiles";
import PerfilEnfermero from "../enfermero/perfil/PerfilEnfermero";
import { getAuthUser } from "../../helpers/configRoutes/backend_helper";

const Perfil = () => {
   const authUser = getAuthUser()
   return (
      <>
         {authUser.perfil_id == 4 ?
            <PerfilEnfermero authUser={authUser} />
            :
            <MisPerfiles authUser={authUser} />
         }
      </>
   )
}
export default Perfil
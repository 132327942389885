import React from 'react'
import { InfoBlock } from '../../../components/InfoBlock'
import { infoBlocks, infoBlocks2 } from '../../../helpers/recursos'

const Garantias = () => {

    return (
        <div className="flex flex-wrap px-4 pb-10 rounded-2xl shadow-sm bg-stone-100 mr-[60px]">
          <div className='w-full md:w-1/2'>
            <header className="pb-8 border-b border-solid border-zinc-400 md:max-w-full mt-10 sm:mt-0">
              <h2 className="text-3xl font-bold leading-9 text-black md:mt-10">Garantías para clientes y pacientes</h2>
            </header>
            {infoBlocks.map((block, index) => (
              <InfoBlock key={index} {...block} />
            ))}
          </div>
          <div className='w-full md:w-1/2'>
            <header className="pb-8 border-b border-solid border-zinc-400 md:max-w-full mt-10 sm:mt-0">
              <h2 className="text-3xl font-bold leading-9 text-black md:mt-10">Garantías para profesionales</h2>
            </header>
            {infoBlocks2.map((block, index) => (
              <InfoBlock key={index} {...block} />
            ))}
          </div>
        </div>
    )
}

export default Garantias
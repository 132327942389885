import { imgUrl } from '../../../helpers/recursosurl'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import BlogResumen from '../blog/BlogResumen'
import { Link } from 'react-router-dom'

const QuienesSomos = () => {

    const navigate = useNavigate();

    return (
        <div className="flex flex-wrap justify-center mt-24 mb-6 w-full px-4">
            <div className="w-full">
                <div className="bg-o2o-gris-calido rounded-t-2xl shadow-lg text-center w-full max-w-o2o mx-auto">
                    <div className='flex flex-col md:flex-row justify-between'>
                        <div className='w-full md:w-3/4 md:mr-10 relative md:relative'>
                            <img className="w-full md:h-150 md:absolute md:bottom-0" src={`${imgUrl}/Group_138.png`} alt="Imagen del card" />
                        </div>
                        <div className='flex flex-col mx-auto w-full max-w-[516px] mt-12 mr-[130px]'>
                            <p className='text-sm text-start mb-4'><Link className='bg-o2o-gris-arena rounded-full p-2' to={'/quienes-somos'}>¿Quienes somos?</Link></p>
                            <h3 className='text-start my-[18px]'>
                                Somos una comunidad que vela por la salud de las personas y sus familias.
                            </h3>
                            <h3 className='text-start my-[18px]'>
                                Creemos que el bienestar es resultado del equilibrio
                                entre la excelencia en la práctica médica y el sentido humano.
                            </h3>
                            <p className='text-start'>
                                One to One Nurses es un servicio respaldado por una de las instituciones más prestigiosas de enfermería en el país:
                                la <b>Escuela de Enfermería Angelópolis.</b>
                            </p>

                            <div className='flex flex-col md:flex-row text-start mt-8 mb-10 space-y-4 md:space-y-0 md:space-x-4'>
                                <CustomButtonOval
                                    className="bg-o2o-gris hover:bg-o2o-gris-hover w-full md:w-auto"
                                    onClick={() => navigate('/quienes-somos')}
                                >
                                    <p className='text-sm font-bold'>Conoce más sobre nosotros</p>
                                </CustomButtonOval>
                                <CustomButtonOval
                                    className="bg-o2o-violeta hover:bg-o2o-violeta-hover w-full md:w-auto"
                                    onClick={() => window.open('https://www.escueladeenfermeriangelopolis.edu.mx', '_blank')}
                                >
                                    <p className='text-sm font-bold'>www.escueladeenfermeriaangelopolis.edu.mx</p>
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='border border-solid border-black' />
                <div className="rounded-b-2xl overflow-hidden bg-stone-200 w-full max-w-o2o mx-auto">
                    <BlogResumen />
                </div>
            </div>
        </div>

    )
}

export default QuienesSomos
import React from 'react'
import { getEstrellas } from '../../../helpers/recursos';

const CardsSatisfaccion = () => {

    const info = [
        {
            titulo: 'Facilidad de uso',
            descripcion: 'La plataforma web es práctica y facilita la contratación de enfermeros.',
            estrellas: 4
        },
        {
            titulo: 'Información clara y oportuna',
            descripcion: 'Los procesos son fáciles de entender y se notifica en tiempo y forma.',
            estrellas: 5
        },
        {
            titulo: 'Soporte al cliente',
            descripcion: 'El Centro de Atención, ZenDesk y líneas telefónicas son útiles.',
            estrellas: 5
        },
        {
            titulo: 'Servicio humano',
            descripcion: 'Nuestros operadores son amables y amistosos en su trato.',
            estrellas: 4
        },
        {
            titulo: 'Alta calidad profesional',
            descripcion: 'Nuestros enfermeros están capacitados para atender cualquier situación médica.',
            estrellas: 5
        },
        {
            titulo: 'Valor del servicio',
            descripcion: 'Los precios son justos y adecuados a la calidad del servicio recibido.',
            estrellas: 5
        },
    ];

    

    return (
        <>
        <div className="flex flex-wrap content-start self-stretch font mb-14">
            {info.map((data,index) => {
                return (
                <div className="w-full sm:w-1/3 p-1" key={index}>
                    <div className="flex flex-col grow p-5 w-full rounded-xl shadow-sm bg-stone-100 max-md:mt-3 h-[100%] ">
                        <div className="text-2xl font-bold text-black ">
                        {data?.titulo}
                        </div>
                        <div className="text-base leading-6 text-black">
                        {data?.descripcion}
                        </div>
                        <div className="flex flex-col justify-center px-9 py-1.5 w-full bg-orange-200 rounded-2xl max-md:px-5 mt-auto">
                            <div className="flex gap-3 pr-20 max-md:pr-5">
                            {getEstrellas(data?.estrellas)} 
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
        </div>
        </>
    )
}

export default CardsSatisfaccion;
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useIsMobile } from '../../../store/hooks/useIsMobile';
import { imgUrl } from '../../../helpers/recursosurl';
import { getEstrellas } from '../../../helpers/recursos';

const CardsCalificacionCliente = () => {

    const { isMobile } = useIsMobile();
    const cards = [
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
        { 
            img: '',  
            descripcion: '',
            user: '',
            fecha: '',
            calificacion: 5
        },
    ];

    return (
        <>
        {/* CARRUSEL */}
        
                <div className='swiperContainer'>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {cards.map((data, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col justify-center px-5 py-1.5 max-w-full bg-orange-200 rounded-2xl shadow-sm ">
                                    <div className="flex gap-3">
                                    {getEstrellas(data?.calificacion)}
                                    </div>
                                </div>
                                <div className="flex flex-col p-5 mt-5 w-full text-black rounded-xl shadow-sm bg-stone-200">
                                    <div className="flex gap-5">
                                        <img
                                            loading="lazy"
                                            srcSet={`${imgUrl}/perfil-adolfo.png`}
                                            className="shrink-0 aspect-square w-[72px]"
                                        />
                                        <div className="flex flex-col shrink-0 my-auto basis-0 w-fit">
                                            <div className="text-2xl font-bold">Adolfo Domínguez</div>
                                            <div className="text-sm leading-4">25 de Octubre 2023</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-base leading-6">
                                    One 2 One salvó la vida de mi esposo. El servicio es impecable y
                                    excelente, nos sentimos seguros y escuchados desde el inicio. La
                                    enfermera está más que capacitada: es amable y apasionada.
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        ) )}
                    </Swiper>
                </div>
        </>
    )
}

export default CardsCalificacionCliente
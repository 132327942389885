import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cliente: {
          tipoPerfil: "",
          nombre: "",
          apellidos: "",
          fechaNacimiento: "",
          movil: "",
          telefono: "",
          email: "",
          contrasena: "",
          confimracionContrasena: "",
          curp: "",
          estadoCivil: "",
          archivo: "",
          calle: "",
          codigoPostal: "",
          numeroExterior: "",
          numeroInterior: "",
          pais: "",
          estado: "",
          colonia: "",
          municipio: "",
          descripcionPersonal: "",
          informacionUnica: "",
          interesesPersonales: [],
          contrato: false,
          codigo: false,
          privacidad: false
    },
    paciente: {
        banderaRegistro: true,
        tipoPerfil: "",
        nombre: "",
        apellidos: "",
        edad: "",
        telefono: "",
        email: "",
        curp: "",
        estadoCivil: "",
        archivo: "",
        calle: "",
        codigoPostal: "",
        numeroExterior: "",
        numeroInterior: "",
        pais: "",
        estado: "",
        colonia: "",
        municipio: "",
        descripcionPersonal: "",
        informacionUnica: "",
        interesesPersonales: [],
        patologia: false,
        diagnosticoMedico: '',
        condicionesEspeciales: '',
        medicoResponsable: '',
        cedulaMedico: '',
        telefonoMedico: '',
        movilMedico: '',
        enfermeroEspecialita: '',
        cuidadosOfreceEnfermero: [],
        alergias: '',
        informacionAdicional: '',
        diasRequeridos: [],
        fechaInicio: '',
        fechaFin: '',
        turno: '',
        tiempo: ''
    },
    informacionDePago: {
        nombreDeTarjeta: '',
        noTarjeta: '',
        vencimiento: '',
        codigoSeguridad: '',
        confirmacionCodigo: '',
        entidadBancaria: '',
        entidadFinanciera: ''
    },
    datosFacturacion: {
        requiereFactura: false,
        mismaDireccion: false,
        nombre: '',
        rfc: '',
        email: '',
        calle: '',
        numeroExterior: "",
        numeroInterior: "",
        pais: "",
        estado: "",
        colonia: "",
        municipio: "",
        codigoPostal: '',
        telefono: '',
        regimenFiscal: '',
        cfdi: '',
        constanciaSituacionFiscal: '',
    },
    errorsForm: []
}

export const RegistroSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCliente: (state, { payload }) => {
            state.cliente = payload;
        },
        setPaciente: (state, {payload}) => {
            state.paciente = payload;
        },
        setInformacionDePago: (state, { payload }) => {
            state.informacionDePago = payload;
        },
        setDatosFacturacion: (state, { payload }) => {
            state.datosFacturacion = payload;
        },
        setErrorsForm: (state, { payload }) => {
            state.errorsForm = payload;
        },
        resetCliente: (state) => {
            state.cliente = {
                tipoPerfil: "",
                nombre: "",
                apellidos: "",
                fechaNacimiento: "",
                movil: "",
                telefono: "",
                email: "",
                contrasena: "",
                confimracionContrasena: "",
                curp: "",
                estadoCivil: "",
                archivo: "",
                mismaDireccion: false,
                calle: "",
                codigoPostal: "",
                numeroExterior: "",
                numeroInterior: "",
                pais: "",
                estado: "",
                colonia: "",
                municipio: "",
                descripcionPersonal: "",
                informacionUnica: "",
                interesesPersonales: [],
                contrato: false,
                codigo: false,
                privacidad: false
            } 
        },
        resetPaciente: (state) => {
            state.paciente = {
                banderaRegistro: true,
                tipoPerfil: "",
                nombre: "",
                apellidos: "",
                edad: "",
                telefono: "",
                email: "",
                contrasena: "",
                confimracionContrasena: "",
                curp: "",
                estadoCivil: "",
                archivo: "",
                calle: "",
                codigoPostal: "",
                numeroExterior: "",
                numeroInterior: "",
                pais: "",
                estado: "",
                colonia: "",
                municipio: "",
                descripcionPersonal: "",
                informacionUnica: "",
                interesesPersonales: [],
                patologia: false,
                diagnosticoMedico: '',
                condicionesEspeciales: '',
                medicoResponsable: '',
                cedulaMedico: '',
                telefonoMedico: '',
                movilMedico: '',
                enfermeroEspecialita: '',
                cuidadosOfreceEnfermero: [],
                alergias: '',
                informacionAdicional: '',
                diasRequeridos: [],
                fechaInicio: '',
                fechaFin: '',
                turno: '',
                tiempo: ''
            }
        },
        resetInformacionDePago: (state) => {
            state.informacionDePago = {
                nombreDeTarjeta: '',
                noTarjeta: '',
                vencimiento: '',
                codigoSeguridad: '',
                confirmacionCodigo: '',
                entidadBancaria: '',
                entidadFinanciera: ''
            }
        },
        resetDatosDeFacturacion: (state) => {
            state.datosFacturacion = {
                requiereFactura: false,
                mismaDireccionAdministrador: false,
                nombre: '',
                rfc: '',
                email: '',
                calle: '',
                numeroExterior: "",
                numeroInterior: "",
                pais: "",
                estado: "",
                colonia: "",
                municipio: "",
                telefono: '',
                regimenFiscal: '',
                cfdi: '',
                constanciaSituacionFiscal: '',
            }
        }

    }
});

export const {
    setCliente,
    setPaciente,
    setDatosFacturacion,
    setInformacionDePago,
    setErrorsForm,
    resetCliente,
    resetPaciente,
    resetDatosDeFacturacion,
    resetInformacionDePago,
    cliente,
    paciente,
    informacionDePago,
    datosFacturacion,
    errorsForm
} = RegistroSlice.actions;
import React from 'react';

const UnderConstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="flex items-center space-x-2">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div>
        <span className="text-2xl font-semibold">En construcción...</span>
      </div>
    </div>
  );
};

export default UnderConstruction;

import React, { useState } from "react";
import CustomInput from "../../inputs/CustomInput";

const ExperienciaLaboral = ({ validation }) => {

    const [experiencias, setExperiencias] = useState([
        { id: 1, cargo: '', actividades: '', fecha_inicio: '', fecha_inicio: '' }
    ]);

    const addExperienciaLaboral = () => {
        const newObjeto = { id: (experiencias.length + 1), cargo: '', actividades: '', fecha_inicio: '', fecha_inicio: '' }
        setExperiencias([...experiencias, newObjeto])
    }
    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:max-w-full max-md:ml-0">
                <div className="w-full text-2xl font-bold text-black">
                    Experiencia laboral
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                {experiencias.map((exp, index) => (
                    <div className="flex flex-wrap mb-10" key={index}>
                        <div className="w-full text-xl font-bold text-black mb-8">
                            {index + 1 === 1 ? `${index + 1} Experiencia más reciente` : `${index + 1} Otra experiencia`}
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Cargo
                            </div>
                            <CustomInput
                                name={`experiencias_laborales[${index}].cargo`}
                                value={validation.values.experiencias_laborales[index]?.cargo || ''}
                                error={validation.errors?.experiencias_laborales?.[index]?.cargo}
                                placeholder={'Jefe de piso'}
                                type={'text'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Actividades
                            </div>
                            <CustomInput
                                name={`experiencias_laborales[${index}].actividades`}
                                value={validation.values.experiencias_laborales[index]?.actividades || ''}
                                error={validation.errors?.experiencias_laborales?.[index]?.actividades}
                                placeholder={'Descripción de actividades'}
                                type={'text'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Fecha de Inicio
                            </div>
                            <CustomInput
                                name={`experiencias_laborales[${index}].fecha_inicio`}
                                value={validation.values.experiencias_laborales[index]?.fecha_inicio || ''}
                                error={validation.errors?.experiencias_laborales?.[index]?.fecha_inicio}
                                placeholder={'Fecha de inicio'}
                                type={'date'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Fecha de Fin
                            </div>
                            <CustomInput
                                name={`experiencias_laborales[${index}].fecha_fin`}
                                value={validation.values.experiencias_laborales[index]?.fecha_fin || ''}
                                error={validation.errors?.experiencias_laborales?.[index]?.fecha_fin}
                                placeholder={'Fecha de fin'}
                                type={'date'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Sigo en esta posición
                            </div>
                            <input
                                name={`experiencias_laborales[${index}].trabajo_actual`}
                                type="checkbox"
                                checked={validation.values.experiencias_laborales[index]?.trabajo_actual || false}
                                onChange={(e) => {
                                    // Handle change manually to update Formik's state
                                    const { checked } = e.target;
                                    validation.setFieldValue(`experiencias_laborales[${index}].trabajo_actual`, checked);
                                }}
                                className="mr-2"
                            />
                            {validation.errors?.experiencias_laborales?.[index]?.trabajo_actual && (
                                <div className="text-red-500 text-sm">{validation.errors.experiencias_laborales[index].trabajo_actual}</div>
                            )}
                        </div>

                    </div>
                ))}
            </div>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[10px] max-md:max-w-full max-md:ml-0">
                <div className="flex flex-row cursor-pointer w-52" onClick={() => addExperienciaLaboral()}>
                    <span className="material-symbols-outlined my-auto">
                        add_circle
                    </span>
                    <p className="my-auto ml-2">Agregar otra experiencia</p>
                </div>
            </div>

        </>
    )
}
export default ExperienciaLaboral;
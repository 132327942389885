import React,{useEffect, useState} from 'react'
import CustomButtonOval from '../../components/buttons/CustomButtonOval';
import { Button, scroller } from 'react-scroll';
import { useRegistroStore } from '../../store/hooks/useRegistroStore';
import { warningToast } from '../../helpers/Toast';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { update_usuario } from "../../store/users/registro/action";
import { useNavigate } from 'react-router-dom';
import CancelarModal from "./CancelarModal";
import { contratos } from '../../helpers/configRoutes/backend_helper';
import parse from 'html-react-parser';

const ContratosLegales = ({ item, setStep }) => {
    const [loading,setLoading]=useState(false)
    const [modal, setModal] = useState(false)
    const [contratosData,setContratosData]=useState({prestacion_servicios:''})
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item.contratos,
        validationSchema: Yup.object({
            prestacion: Yup.boolean()
                .oneOf([true], 'Prestación de Servicios, tiene que ser firmado para continuar'),
            codigo: Yup.boolean()
                .oneOf([true], 'Código de Conducta, tiene que ser firmado para continuar'),
            privacidad: Yup.boolean()
                .oneOf([true], 'Aviso de Privacidad, tiene que ser firmado para continuar'),
        }),
        onSubmit: async (values, { setErrors }) => {
            let newItem = { ...item, contratos: values }
            dispatch(update_usuario(newItem))
            window.scrollTo(0, 500);
            setStep(2)
        },
    });

    useEffect(() => {
        const getContratos = async () => {
            setLoading(true)
            const response = await contratos({params:{contrato:'prestacion_servicios'}});
            const responseConducta = await contratos({params:{contrato:'codigo_conducta'}});
            const responsePrivacidad = await contratos({params:{contrato:'aviso_privacidad'}});
            
            setContratosData(
                {
                    ...contratosData,
                    'prestacion_servicios':response.contrato,
                    'codigo_conducta':responseConducta.contrato,
                    'aviso_privacidad':responsePrivacidad.contrato
                }
            )
            setLoading(false)
        }
        getContratos()
    }, []);

    /*const validarCondiciones = () => {
        if (
            data?.contrato &&
            data?.codigo &&
            data?.privacidad
        ) {
            setStep(2);
            scroller.scrollTo("inicio", { duration: 1000, delay: 100, smooth: true })
        } else {
            warningToast('Por favor, acepte todos contratos legales.');
            scroller.scrollTo("inicio", { duration: 1000, delay: 100, smooth: true })
        }

    }*/

    const showFormErrors = () => {
        let errors = validation.errors;
        for (const key in errors) {
            if (errors[key]) {
                warningToast(errors[key]);
            }
        }
    };

    return (
        <>
            <div className="flex flex-wrap justify px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5 mt-10">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>{contratosData.prestacion_servicios?.last_upd}
                    </div>
                </div>
                {contratosData.prestacion_servicios?.body &&
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Contrato de Prestación de Servicios
                    </div>
                    {parse(contratosData.prestacion_servicios?.body)}
                </div>
                }

            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mt-10 mb-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.prestacion && (
                    <CustomButtonOval
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        onClick={() => validation.setFieldValue('prestacion', true)}
                        type='button'
                        loading={loading}
                        textCarga={'Cargando'}
                    >
                        Firmar el Contrato de Prestación de Servicios
                    </CustomButtonOval>
                )}

            </div>
            {/* ---------------------------------------------------------------------- */}
            <div className="flex flex-wrap px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>{contratosData.codigo_conducta?.last_upd}
                    </div>
                </div>
                {contratosData.codigo_conducta?.body &&
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Código de Conducta
                    </div>
                    {parse(contratosData.codigo_conducta?.body)}
                </div>
                }
            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mt-10 mb-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.codigo && (
                    <CustomButtonOval
                        loading={loading}
                        textCarga={'Cargando'}
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        onClick={() => validation.setFieldValue('codigo', true)}
                        type='button'
                    >
                        Firmar el Código de Conducta
                    </CustomButtonOval>
                )}

            </div>
            {/* ----------------------------------------------------------------------------------------------------------------------- */}

            <div className="flex flex-wrap px-5 py-5 text-black rounded-2xl border border-solid border-stone-200 max-md:px-5">
                <div className="w-full sm:w-1/3  justify-center p-3 text-sm leading-5 ">
                    <div className='rounded-xl border border-violet-700 border-solid px-3 py-3'>
                        <span className="font-bold">Última actualización: </span>{contratosData.aviso_privacidad?.last_upd}
                    </div>
                </div>
                {contratosData.aviso_privacidad?.body &&
                <div className="w-full sm:w-2/3 text-base leading-6">
                    <div className="text-3xl leading-9 max-md:max-w-full">
                        Aviso de Privacidad
                    </div>
                    {parse(contratosData.aviso_privacidad?.body)}
                </div>
                }
            </div>
            <div className="flex flex-wrap gap-5 justify-center p-5 text-black rounded-xl shadow-sm bg-stone-100 mb-10 mt-10">
                <div className="flex-auto text-base font-bold leading-6 text-right max-md:max-w-full">
                    He leído, acepto y doy mi consentimiento expreso de los términos
                    descritos.
                </div>
                {!validation.values?.privacidad && (
                    <CustomButtonOval
                        loading={loading}
                        textCarga={'Cargando'}
                        className={'bg-o2o-aqua hover:bg-o2o-aqua-hover text-sm'}
                        type='button'
                        onClick={
                            () => validation.setFieldValue('privacidad', true)
                        }
                    >
                        Firmar el Aviso de Privacidad
                    </CustomButtonOval>
                )}
            </div>
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                showFormErrors()
                return false;
            }}
            >
                <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                    <button
                        className="grow justify-center items-center px-16 py-5 shadow-sm bg-stone-100 rounded-[36px] w-fit max-md:px-5 max-md:max-w-full hover:bg-o2o-gris-hover"
                        type='button'
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Cancelar registro
                    </button>
                    <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                        Guardar información y continuar
                    </button>
                </div>
            </form>
            <CancelarModal modal={modal} setModal={setModal}/>
        </>
    )
}

export default ContratosLegales;
import {
  UPDATE_USUARIO,
  RESET_USUARIO,
  ADD_ERRORS
} from "./actionTypes"

export const update_usuario = usuario => {
  return {
    type: UPDATE_USUARIO,
    payload: usuario,
  }
}

export const reset_usuario = () => {
  return {
    type: RESET_USUARIO,
  }
}

export const add_errors = errors => {
  return {
    type: ADD_ERRORS,
    payload: errors
  }
}
import React, { useState, useEffect } from "react";
import Servicios from "../Servicios";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate, useParams } from "react-router-dom";
import StarRating from "../../../components/CalificacionEstrellas";
import { imgUrl } from "../../../helpers/recursosurl";
import MisServicios from "../../enfermero/mis_servicios/MisServicios";
import { serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { FormatCurrency } from "../../../helpers/CurencyFormat";




const CalificacionPrimerdia = ({ servicio, setComponent }) => {
    const { id, id_cita } = useParams();
    const navigate = useNavigate();
    // const [servicio, setServicio] = useState({});

    // useEffect(() => {
    //     const getServicio = async () => {
    //         const response = await serviciosResource('show', {},id);

    //         setServicio(response.servicio);
    //     }
    //     getServicio();
    // }, []);

    return (
        <>
            <div className="flex flex-col items-start mt-11 ml-[19%] sm:ml-[10%] md:ml-[5%] lg:ml-[19%] xl:ml-[19%]">
                <CustomButtonOval
                    className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover mb-8"
                    onClick={() => navigate('/servicios')}
                >
                    <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                    >
                        <path
                            d="M5.80078 1L1.00078 5L5.80078 9"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Regresar a solicitudes de servicio
                </CustomButtonOval>

                <div className="border-0 rounded-lg p-4 mb-4 w-full sm:w-[90%] md:w-[75%] lg:w-[60%] xl:w-[547px]">
                    <div className="text-2xl mb-4 font-semibold">
                        <h3>¡Gracias!</h3>
                        <h3>Hemos recibido tu evaluación de {servicio.paciente?.nombre}.</h3>
                    </div>
                    <div className="text-base">
                        <p>Valoramos grandemente tu opinión y la tomaremos en cuenta para ajustar el servicio que nuestros profesionales te ofrecen.</p>
                        <p className="mt-5">Al calificar el primer día de servicio, <strong>conservas todas las garantías que One 2 One ofrece</strong> para protegerte a ti y tus pacientes, como cambio de enfermero en caso de mal desempeño, entre otros.</p>
                    </div>
                </div>

                <div className="flex space-x-2">
                    <CustomButtonOval
                        onClick={() => setComponent(false)}
                        className="bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover"
                    >
                        Aceptar y regresar al historial
                    </CustomButtonOval>
                </div>
            </div>

        </>
    )
}
export default CalificacionPrimerdia;
import React from "react";
import ToogleButton from "../../buttons/ToogleButton";
import CustomInput from "../../inputs/CustomInput";
import DireccionDatos from "../DireccionDatos";

const Facturacion = ({ validation, adminDireccion }) => {
    return (
        <>
            <div className="flex flex-wrap max-w-full px-4 mt-10">
                <div className="w-full md:w-1/2 px-2 mb-4">
                    <div className="bg-stone-100 shadow-lg rounded-lg py-3 px-4">
                        <ToogleButton
                            name="requiereFactura"
                            label="¿Requiero factura?"
                            checked={validation.values.is_factura}
                            onChange={() => {
                                validation.setFieldValue('is_factura', !validation.values.is_factura);
                            }}
                        />
                    </div>
                </div>
                {validation.values.is_factura && (
                    <div className="w-full md:w-1/2 px-2 mb-4">
                        <div className="bg-stone-100 shadow-lg rounded-lg py-3 px-4">
                            <ToogleButton
                                name="dir_admin"
                                label="¿Usar la misma direccion que el administrador?"
                                checked={validation.values.dir_admin}
                                onChange={(e) => {
                                    validation.setFieldValue('dir_admin', !validation.values.dir_admin);
                                    adminDireccion(e)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>

            {validation.values.is_factura &&
                <div className="flex flex-wrap max-w-full px-4 mt-10">
                    <div className="w-full text-base font-bold leading-6 max-md:max-w-full">
                        Datos de facturación
                    </div>
                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Nombre completo
                        </div>
                        <div className="justify-center ">
                            <CustomInput
                                type={'text'}
                                placeholder={'Nombre completo'}
                                name="razon_social"
                                value={validation.values?.razon_social || ''}
                                error={validation.errors?.razon_social}
                                onChange={validation.handleChange}
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            RFC
                        </div>
                        <div className="justify-center ">
                            <CustomInput
                                type={'text'}
                                placeholder={'RFC'}
                                name="rfc"
                                value={validation.values?.rfc || ''}
                                error={validation.errors?.rfc}
                                onChange={validation.handleChange}
                                maxLength="13"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            E-mail
                        </div>
                        <CustomInput
                            placeholder={'example@mail.com'}
                            type={'email'}
                            name="email"
                            value={validation.values?.email || ''}
                            error={validation.errors?.email}
                            onChange={validation.handleChange}
                        />
                    </div>
                    <DireccionDatos validation={validation} titulos={false} />
                </div>
            }
        </>
    )
}
export default Facturacion;
import React, { useState, useEffect } from "react";
import PerfilPaciente from "../../../components/registro/administrador/PerfilPaciente";
import { useSelector } from "react-redux";
import CustomTabsPerfiles from "../../../components/CustomTabsPerfiles";
import { useNavigate, useParams } from "react-router-dom";
import { pacientesResource } from "../../../helpers/configRoutes/backend_helper";

const AddUpdPaciente = () => {
    const navigate = useNavigate()
    const objRedux = useSelector(state => state.Usuario.usuario);
    const [item, setItem] = useState(null)
    const { id } = useParams()

    const tabs = [
        { id: 1, label: 'Administrador', content:'', action:'/perfiles' },
        { id: 2, label: 'Pacientes', content: '', disabled:true },
    ];

    useEffect(() => {
        const getDataPaciente = async () => {
            if (id) {
                const response = await pacientesResource('show', {}, id)
                if(response.status){
                    setItem(response.paciente)
                }
            } else {
                setItem(objRedux);
            }
        }
        getDataPaciente()
    }, []);

    return (
        <>
            <CustomTabsPerfiles defaultTab={2} tabs={tabs}/>
            {item &&
                <>
                    <PerfilPaciente item={item.id ? item:item.paciente} />
                </>
            }
        </>
    )
}

export default AddUpdPaciente;
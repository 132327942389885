import React, { useState } from "react";


const Paginacion = ({ items, setFilters, filters, meta }) => {
    const itemsPerPage = filters.perPage;
    const paginateItems = items.slice(meta.current_page * itemsPerPage, (meta.current_page + 1) * itemsPerPage);

    const totalPages = Math.ceil(meta.total / itemsPerPage);


    const handleNextPage = () => {
        setFilters({ ...filters, page: meta.current_page + 1 });
    };

    const handlePrevPage = () => {
        setFilters({ ...filters, page: meta.current_page - 1 });
    };

    const handlePageClick = (pageIndex) => {
        setFilters({ ...filters, page: pageIndex });
    };
    return (

        <div className="flex flex-wrap justify-center mt-4 space-x-2">
            <button
                onClick={handlePrevPage}
                disabled={meta.current_page === 1}
                className="p-2 rounded disabled:opacity-50"
            >
                {'<< Anterior'}
            </button>
            {Array.from({ length: totalPages }).map((_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageClick(index + 1)}
                    className={`p-2 m-1 rounded ${meta.current_page === index + 1 ? 'font-bold' : ''}`}
                >
                    {index + 1}
                </button>
            ))}
            <button
                onClick={handleNextPage}
                disabled={meta.current_page === totalPages}
                className="p-2 rounded disabled:opacity-50"
            >
                {'Siguiente >>'}
            </button>
        </div>

    )
}

export default Paginacion;
import React, { useEffect, useState } from 'react'
import { imgUrl } from '../../../helpers/recursosurl';

const BarraAvance = ({ step, setStep, infoflag, perfil_id }) => {
    const [porcentaje, setPorcentaje] = useState(0)
    const pasos = [
        {
            numero: 1,
            titulo: 'Contratos y legales',
            requrimiento: true,
            descripcion: 'Firma digital de contrato, aviso de privacidad y código de conducta',
            perfil_id: 1
        },
        {
            numero: 2,
            titulo: 'Crear el perfil admin',
            requrimiento: true,
            descripcion: 'Creación del perfil administrador de la cuenta y credenciales',
            perfil_id: 1
        },
        {
            numero: 3,
            titulo: 'Crear un perfil paciente',
            requrimiento: false,
            descripcion: 'Creación de perfil de un paciente con sus características médicas',
            perfil_id: 1
        },
        {
            numero: 4,
            titulo: 'Información de cobro',
            requrimiento: true,
            descripcion: 'Carga de datos bancarios para el cobro eventual de servicios',
            perfil_id: 1
        },
        {
            numero: 5,
            titulo: 'Creación de cuenta',
            requrimiento: true,
            descripcion: 'Confirmación de la creación de cuenta e inicio de sesión',
            perfil_id: 1
        },
        {
            numero: 1,
            titulo: 'Contratos y legales',
            requrimiento: true,
            descripcion: 'Firma digital de Contrato, Aviso de Privacidad y Código de Conducta',
            perfil_id: 2
        },
        {
            numero: 2,
            titulo: 'Crear el perfil prfesional',
            requrimiento: true,
            descripcion: 'Creación del perfil de proveedor de servicios con datos generales',
            perfil_id: 2
        },
        {
            numero: 3,
            titulo: 'Subir CV y acreditaciones',
            requrimiento: false,
            descripcion: 'Completar información profesional, CV, turnos y servicios ofrecidos',
            perfil_id: 2
        },
        {
            numero: 4,
            titulo: 'Información de pagos',
            requrimiento: true,
            descripcion: 'Carga de datos bancarios para el pago de servicios',
            perfil_id: 2
        },
        {
            numero: 5,
            titulo: 'Creación de cuenta',
            requrimiento: true,
            descripcion: 'Confirmación de la creación de cuenta e inicio de sesión',
            perfil_id: 2
        },
    ];

    return (
        <>

            <div className="flex gap-5 px-5 mt-6 w-full max-md:flex-wrap max-md:max-w-full">
                {pasos.map((data, index) => {
                    if (data.perfil_id == perfil_id) {
                        return (
                            <div className="flex flex-col flex-1 self-start" key={index} onClick={() => setStep(data?.numero)}>
                                <div className="flex gap-1.5 text-center whitespace-nowrap">
                                    <div className={`justify-center px-2.5 py-1 text-sm leading-4 rounded-md ${step >= data?.numero ? 'bg-o2o-aqua' : 'bg-stone-100'}`}>
                                        {data?.numero}
                                    </div>
                                    <div className={`justify-center px-2 py-1.5 text-xs leading-3 rounded-md border border-solid  ${step >= data?.numero ? 'border-o2o-aqua' : 'border-stone-100'}`}>
                                        {data?.requrimiento ? 'Obligatorio' : 'Opcional'}
                                    </div>
                                </div>
                                <div className="mt-1.5 text-base font-bold leading-6">
                                    {data?.titulo}
                                </div>
                                {infoflag ? (
                                    <div className="mt-1.5 text-sm leading-5">
                                        {data?.descripcion}
                                    </div>
                                ) : null}
                            </div>
                        )
                    }
                })}
            </div>

        </>
    )
}

export default BarraAvance
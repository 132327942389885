import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
  } from "./actionTypes";
  
  const initialState = {
    error: "",
    loading: false,
    user: JSON.parse(localStorage.getItem("authUser"))?.user || {},
    auth: {
        token: JSON.parse(localStorage.getItem("authUser"))?.token || '',
        isAuth: JSON.parse(localStorage.getItem("authUser"))?.isAuth || false
    },
  };
  
  const login = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER:
        return {
          ...state,
          loading: true,
        };
      case LOGIN_SUCCESS:
        localStorage.setItem("authUser", JSON.stringify(action.payload));
        return {
          ...state,
          user: action.payload,
          auth:{
            token:JSON.parse(localStorage.getItem("authUser"))?.token,
            isAuth:JSON.parse(localStorage.getItem("authUser"))?.isAuth
          },
          loading: false,
        };
      case LOGOUT_USER:
        return {
          ...state,
        };
      case LOGOUT_USER_SUCCESS:
        localStorage.removeItem("authUser");
        return {
          ...state,
          user: {},
          auth: {}
        };
      case API_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default login;
  
import React, { useState } from 'react'

const CustomTabs = ({ defaultTab, tabs }) => {

    const [activeTab, setActiveTab] = useState(defaultTab);

    const handleClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="border-b border-gray-200">
            <div className="flex border-b-2 border-black">
                {tabs.map((tab, index) => (
                <button
                    key={tab.id}
                    className={`${
                    activeTab === tab.id
                        ? 'border-black text-black'
                        : 'border-transparent text-black-500 hover:text-gray-700 bg-o2o-gris-arena'
                    } py-2 px-6 block border-t-2 border-l-2 border-r-2 rounded-t-lg font-medium focus:outline-none ml-2`}
                    onClick={() => handleClick(tab.id)}
                >
                    {tab.label}
                </button>
                ))}
            </div>
            <div className="mt-2">
                {tabs.map((tab) => (
                <div key={tab.id} className={`${activeTab === tab.id ? 'block' : 'hidden'}`}>
                    {tab.content}
                </div>
                ))}
            </div>
        </div>
    )
}

export default CustomTabs
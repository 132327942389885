import { SET_CONTADOR } from "./actionTypes";

const initialState = {
    sin_leer: localStorage.getItem("contadorNotificaciones") || 0,
};

const mensajesContador = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTADOR:
            // Actualizar el estado
            const newState = {
                ...state,
                sin_leer: action.payload,
            };
            localStorage.setItem("contadorNotificaciones", action.payload);
            return newState;
        default:
            return state;
    }
};

export default mensajesContador;

import React from 'react'
import CardsCalificacionCliente from './CardsCalificacionCliente';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import CustomButtonOval from '../../../components/buttons/CustomButtonOval';
import { useNavigate } from 'react-router-dom';

const CardEnfermeroInfo = () => {

    const navigate = useNavigate()

    const cards = [
        {},
        {},
        {},
    ];

    return (
        <>
            <div className='flex flex-wrap justify-center items-center font mb-10'>
                <div className="text-base text-black rounded-2xl">
                    <span className='text-2xl font-bold'>Reviews sobre nuestro equipo de enfermer@s</span>
                </div>
            </div>
            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {cards.map((data, index) => (
                    <SwiperSlide key={index}>
                        <div className='flex flex-wrap justify-center items-center font'>
                            <div className="text-base text-black rounded-2xl shadow-xl bg-stone-100 w-1/3">
                                <img
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/eb2d8739db7c147db15902d7ddfa79119d5ac9cc75af60a825e67b8ae9c35cc7?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
                                    className="w-full aspect-[1.39]"
                                />
                                <div className="flex flex-col px-5 pb-5 mt-5 w-full">
                                    <div className="flex flex-col pb-5 border-b border-solid border-stone-200">
                                        <div className="text-2xl font-bold">Renata R.</div>
                                        <div className="leading-[150%]">34 años</div>
                                        <div className="mt-3 font-bold leading-6">Técnico en enfermería</div>
                                        <div className="mt-3 leading-[150%]">Con especialidad en:</div>
                                        <div className="flex flex-wrap gap-1.5 content-start mt-2 text-xs leading-3 text-center whitespace-nowrap">
                                            <div className="justify-center px-2 py-1.5 rounded-md border border-solid bg-stone-200 border-stone-200">
                                                Ortopedia
                                            </div>
                                            <div className="justify-center px-2 py-1.5 rounded-md border border-solid bg-stone-200 border-stone-200">
                                                Fisioterapia
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 leading-6">
                                        Enfermera especializada en pediatría, alergias, inmunología y
                                        neumología. Busco, con mi trabajo, contribuir a la buena salud de las
                                        personas y las familias mexicanas.
                                    </div>
                                </div>
                            </div>
                            <div className='w-full -ml-32'> {/* -ml-60 mt-36 */}
                                <CardsCalificacionCliente />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className='flex flex-wrap justify-center items-center font mt-10'>
                <div className="text-base text-black rounded-2xl w-2/3 text-center mb-5">
                    <span className='text-[48px] font-bold ml-[50px]'>La voz de nuestros enfermeros es igual de importante</span>
                </div>
                <div className="text-base text-black rounded-2xl w-2/3 text-center mb-5">
                    <span className='text-base font-normal'>Los profesionistas de One to One también califican el desempeño de los clientes que los contratan, creando un antecedente que permite a colegas tomar las mejores decisiones respecto a su trabajo.</span>
                </div>
                <div className="text-base text-black rounded-2xl w-2/3 text-center flex justify-center space-x-4 font-semibold">
                    <CustomButtonOval
                        className='bg-o2o-gris hover:bg-o2o-gris-hover'
                        onClick={() => navigate('/quienes-somos')}
                    >
                        ¿Quienes somos?
                    </CustomButtonOval>
                    <CustomButtonOval
                        className='bg-o2o-gris hover:bg-o2o-gris-hover'
                        onClick={() => navigate('/como-funciona')}
                    >
                        ¿Cómo funciona?
                    </CustomButtonOval>
                </div>
            </div>

        </>
    )
}

export default CardEnfermeroInfo
import React, { useState } from 'react';

// Función para obtener el texto según el índice de la estrella
const getText = (index) => {
  switch (index) {
    case 0:
      return 'Nada satisfecho';
    case 1:
      return 'Poco satisfecho';
    case 2:
      return 'No muy satisfecho';
    case 3:
      return 'Satisfecho';
    case 4:
      return '¡Muy satisfecho!';
    default:
      return '';
  }
};

const StarRating = ({ setRating, id, global = false, rating, maxStars = 5, color = '#00BCD4', estatico = false, button = false }) => {

  const [hoverIndex, setHoverIndex] = useState(null);

  const handleHover = (index) => {
    if (!estatico) {
      setHoverIndex(index);
    }
  };

  const handleClick = (index) => {
    if (!estatico) {
      setRating((index + 1), id, global);
      setHoverIndex(null);
    }
  };

  const handleMouseLeave = () => {
    if (!estatico) {
      setHoverIndex(null);
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex justify-center space-x-2 mb-2"> {/* Añadir mb-2 para margen inferior */}
        {[...Array(maxStars)].map((_, index) => (
          <div key={index} className="flex flex-col items-center">
            <svg
              className={`${button ? 'h-5 w-4':'h-8 w-8'} cursor-pointer`}
              fill={index < (hoverIndex !== null ? hoverIndex + 1 : rating) ? color : '#B0B0B0'}
              viewBox={button ? "0 0 20 18":"0 0 24 24"}
              stroke="none"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            >
              <path d="M12 17.27L18.18 21 16.54 14.14 22 9.24l-6.36-.55L12 2 8.36 8.69 2 9.24l5.46 4.9L5.82 21z" />
            </svg>
            {!button &&
              <span className="text-xs mt-1">{index + 1}</span>
            }
          </div>
        ))}
      </div>
      {!button &&
        <div className="mt-2 text-center w-full"> {/* Ajuste del margen superior */}
          {(hoverIndex !== null || rating > 0) ? (
            <span className="text-sm font-semibold">
              {hoverIndex !== null ? getText(hoverIndex) : getText(rating - 1)}
            </span>
          ) : (
            <span className="text-sm font-semibold">Sin calificación</span>
          )}
        </div>
      }
    </div>



  );
};

export default StarRating;

export { getText };

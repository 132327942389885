import React, { useState, useEffect } from "react";
import { serv, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { Link } from "react-router-dom";
import { imgUrl } from "../../../helpers/recursosurl";
import { FormatCurrency } from "../../../helpers/CurencyFormat";
import Paginacion from "../../../components/Paginacion";
import QrServicios from "../../../components/QrServicios";
import { useNavigate } from "react-router-dom";
import { HeaderCardAdmin, TitleAdmin } from "../../../components/solicitudes/SolicitudElements";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import Calificaciones from "../../servicios/calificaciones/Calificaciones";
import StarRating from "../../../components/CalificacionEstrellas";
import DeclinarServicio from "../../enfermero/mis_servicios/DeclinarServicio";


const TabsAdmin = ({ setComponent, estatus_id }) => {
    const qrvalue = `url del back`;
    const navigate = useNavigate();
    const [filters, setFilters] = useState({ perPage: 5, page: 1, estatus_id: estatus_id })
    const [meta, setMeta] = useState({ total: 0, current_page: 0 })
    const [servicios, setServicios] = useState([])
    useEffect(() => {
        const getServicio = async () => {
            const response = await serviciosResource('get', { params: filters });

            setServicios(response.servicios);
            setMeta(response.meta)
            // setMeta(response.meta)
            // setPacientes(response.pacientes);
        }
        getServicio();

    }, [filters]);

    return (
        <div className="flex flex-wrap max-w-[75%] ml-[19%] mr-[60px]">
            <TitleAdmin estatus_id={estatus_id} />
            {servicios.map((ser, index) => (
                <div className={`border rounded-lg p-4 mb-4 ${estatus_id == 5 ? '' : 'bg-o2o-gris-calido-hover'} w-full md:w-[calc(100% - 30px)]`} key={index}>
                    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                        <div className="text-base font-semibold mb-2 md:mb-0">
                            Servicio para {ser?.paciente?.nombre} {ser?.paciente?.apellidos}
                        </div>
                        <p className="mb-2 md:mb-0">{ser?.folio}</p>
                        <div className="flex flex-wrap gap-2">
                            {estatus_id == 9 ? (
                                <>
                                    <CustomButtonOval
                                        onClick={() => setComponent(<Calificaciones id={ser.cita_activa.id} servicio={ser} action={'Cita'} setComponent={setComponent} />)}
                                        isDisabled={!ser.cita_activa?.can?.calificar}
                                        className="flex justify-center bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover"
                                    >
                                        {ser.cita_activa?.primer_cita ? 'Calificar primer día de servicio' : 'Calificar cita'}
                                    </CustomButtonOval>
                                    <CustomButtonOval
                                        onClick={() => setComponent(<DeclinarServicio setFilters={setFilters} filters={filters} servicio={ser} setComponent={setComponent} />)}
                                        className="flex justify-center bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover"
                                    >
                                        Cancelar servicio
                                    </CustomButtonOval>
                                </>
                            ) : estatus_id == 5 ? (
                                <>
                                    <CustomButtonOval
                                        className="flex justify-center text-sm "
                                    >
                                        Estamos esperando la confirmacion de tu enfermero.
                                    </CustomButtonOval>
                                    <CustomButtonOval
                                        onClick={() => setComponent(<DeclinarServicio setFilters={setFilters} filters={filters} servicio={ser} setComponent={setComponent} />)}
                                        className="flex justify-center bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover"
                                    >
                                        Cancelar solicitud
                                    </CustomButtonOval>
                                </>
                            ) : (
                                <>
                                    {ser.calificacion == 'n/a' ?
                                        <CustomButtonOval onClick={() => setComponent(<Calificaciones id={ser.id} servicio={ser} action={'Servicio'} setComponent={setComponent} />)} className="bg-o2o-aqua-hover text-sm">Pendiente de calificar</CustomButtonOval>
                                        :
                                        <CustomButtonOval className="bg-o2o-gris-calido-hover text-sm">
                                            Calificado
                                            <StarRating  rating={ser.calificacion} color={'#FFA000'} button={true} estatico={true} />
                                        </CustomButtonOval>
                                    }
                                    <CustomButtonOval
                                        className="flex justify-center bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover"
                                    >
                                        Servicio concluido
                                    </CustomButtonOval>
                                </>
                            )}
                        </div>
                    </div>

                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className="lg:w-1/2 flex flex-col lg:flex-row">
                            <div className="flex flex-col">
                                <div className="text-[24px] font-semibold mb-[24px] mt-[37px]">
                                    {estatus_id == 5 ? (
                                        <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5 mb-5 lg:mb-0">
                                            <img
                                                loading="lazy"
                                                srcSet={ser.paciente?.perfilImg || `${imgUrl}/user.png`}
                                                className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                                            />
                                        </div>
                                    ) : (
                                        <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5 mb-5 lg:mb-0">
                                            <img
                                                loading="lazy"
                                                srcSet={ser.enfermero?.perfilImg || `${imgUrl}/user.png`}
                                                className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                {estatus_id == 5 ? (
                                    <div className="text-sm font-semibold mb-3 flex items-center space-x-2">
                                        <p className="mr-2">{ser.paciente?.nombre} {ser.paciente?.apellidos}</p>
                                        <p>{ser.paciente?.edad} años</p>
                                    </div>
                                ) : (
                                    <div className="text-sm font-semibold mb-3">
                                        <p className="mr-2">{ser.enfermero?.nombre} {ser.enfermero?.apellidos}</p>
                                    </div>
                                )}
                                <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid">
                                    {estatus_id == 5 ? (
                                        <>
                                            <div className="text-sm font mb-[18px]">
                                                {ser.paciente?.diagnosticoM}
                                            </div>
                                            <div className="text-sm font mb-2">
                                                Enfermero con especialidad en
                                            </div>
                                            <div>
                                                <button className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 bg-o2o-gris-arena-button text-sm mb-[18px]">{ser.enfermero?.especialidad}</button>
                                            </div>
                                            <div className="flex space-x-2">
                                                <Link className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" to={`/enfermero-detallado/${ser.enfermero?.usuario_id}`}>Ver perfil completo</Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-sm font mb-[18px]">
                                                <p>{ser.enfermero?.nivel_nombre} en enfermería con especialidad en {ser.enfermero.especialidad}</p>
                                                <p>Cédula {ser.enfermero?.cedula}</p>
                                            </div>
                                            <div className="flex space-x-2">
                                                <Link className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" to={`/enfermero-detallado/${ser.enfermero?.usuario_id}`}>Ver perfil completo</Link>
                                                <CustomButtonOval className="flex justify-end bg--gris-arena-button text-sm hover:bg-gris-arena-hover">Reportar perfil</CustomButtonOval>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:w-1/2 lg:flex-row">
                            {estatus_id === 5 ? (
                                <div className="lg:w-1/2 flex flex-col items-right lg:items-start mb-4 lg:mb-0">
                                    <div className="w-full mb-3 border-t border-o2o-gris-arena border-solid">
                                        <div className="text-sm font-semibold">
                                            Condiciones especiales
                                        </div>
                                        <div className="text-sm font">
                                            {ser.paciente?.condicionEsp}
                                        </div>
                                    </div>
                                </div>
                            ) : estatus_id === 9 ? (
                                <div className="lg:w-1/2 flex flex-col justify-end items-right lg:items-end mb-4 lg:mb-0">
                                    {ser.cita_proxima?.can?.seeQr ? (
                                        <div className="w-full mb-3 border-t border-o2o-gris-arena border-solid">
                                            <p>Codigo para acceso manual: {ser.cita_proxima?.token}</p>
                                            <QrServicios value={ser.cita_proxima?.url} />
                                            <p>Fecha de tu proxima cita: {ser.cita_proxima?.fecha_inicial}</p>
                                        </div>
                                    ) : (
                                        <p>Fecha de tu proxima cita: {ser.cita_proxima?.fecha_inicial}</p>
                                    )}
                                </div>
                            ) : null}

                            <div className="lg:w-1/2 flex flex-col items-left lg:items-left mb-4 lg:mb-0">
                                <div className="w-full mb-3 border-t border-o2o-gris-arena border-solid">
                                    <div className="text-sm font-semibold">
                                        Periodo del Servicio
                                    </div>
                                    <div className="text-sm font">
                                        <p>{ser.fecha_inicial} al {ser.fecha_fin}</p>
                                        <p>
                                            {ser.dias_names.map((d, index) => (
                                                <span key={index}>{d.dia}, </span>
                                            ))}
                                        </p>
                                        <p>Turno {ser.tiempo?.nombre} {ser.turno?.nombre}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between mt-4">
                        <div className="flex flex-col flex-grow items-center lg:items-start mr-0 lg:mr-[200px]">
                            <div className="text-base font-semibold">Costo total del servicio</div>
                            <div className="text-base font-semibold">{FormatCurrency(ser.pagos?.costo_total)}</div>
                        </div>
                    </div>

                </div>

            ))
            }
            {
                servicios.length > 0 && (
                    <div className="flex justify-center items-center h-full w-full">
                        <Paginacion items={servicios} filters={filters} setFilters={setFilters} meta={meta} />
                    </div>
                )
            }

        </div >
    )

}

export default TabsAdmin
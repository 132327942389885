import React, { useEffect, useState } from "react";
import { imgUrl } from "../../helpers/recursosurl";
import FileInput from '../inputs/FileInput'
import CustomInput from '../inputs/CustomInput'
import CustomSelect from '../inputs/CustomSelect'
import DireccionDatos from "./DireccionDatos";
import CustomTextArea from '../inputs/CustomTextArea'
import { getSexosGeneros } from "../../helpers/configRoutes/backend_helper";

const DatoPerfil = ({ validation }) => {
    const c_estadoCivil = [
        { value: 'Soltero', name: 'Soltero' },
        { value: 'Casado', name: 'Casado' },
        { value: 'Divorciado', name: 'Divorciado' },
        { value: 'Viudo', name: 'Viudo' }
    ]

    const handleFileChange = async (archivo, name) => {
        try {
            const base64 = await getBase64(archivo);
            const formato = archivo.type

            validation.setFieldValue(name, base64)
            validation.setFieldValue(`${name}_formato`, formato)
        } catch (error) {
            console.error('Error al convertir el archivo a Base64:', error);
        }
    }

    const getBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };


    const [sexos,setSexos] = useState([])
    useEffect(() => {
        const getSexos = async () => {
            const response = await getSexosGeneros();
            setSexos(response.sexos)
        }
        getSexos()
    }, []);

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] max-lg:ml-0">
                <div className="w-full text-2xl font-bold text-black">
                    {validation.values.perfil_id === 1 ? "Perfil administrador" : "Perfil profesional"}
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[18%] max-md:w-full">
                            <img
                                loading="lazy"
                                srcSet={validation.values.perfilImg || `${imgUrl}/user.png`}
                                className="shrink-0 max-w-full aspect-square w-[156px] max-md:mt-10"
                            />
                            <span className="text-red-600">
                                {validation.errors?.perfilImg ? 'La imagen es requerida' : ''}
                            </span>
                        </div>
                        <div className="flex flex-col ml-5 w-[82%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow px-5 mt-20 text-sm text-black max-md:mt-10">
                                <div className="self-start">
                                    <FileInput
                                        format={'img'}
                                        onChange={(e) => handleFileChange(e, 'perfilImg')}
                                    />
                                </div>
                                <div className="mt-3 leading-5">
                                    La fotografía de perfil deberá mostrar a la persona desde el
                                    pecho con el rostro descubierto y viendo al frente. El fondo
                                    deberá ser de preferencia claro y liso.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 w-full text-2xl font-bold text-black">
                    Información personal
                </div>
                <div className='w-full border-t border-black border-solid mb-3'></div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        CURP 
                    </div>
                    <div className="justify-center">
                        <CustomInput
                            name="curp"
                            value={validation.values.curp || ''}
                            error={validation.errors?.curp}
                            placeholder={'Curp'}
                            type={'text'}
                            onChange={(e) => validation.setFieldValue('curp',e.target.value.toUpperCase())}
                            onBlur={validation.handleBlur}
                            maxLength="18"
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/4 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Estado civil 
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="estadoCivil"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...c_estadoCivil?.map(tipo => {
                                    return { value: tipo?.value, label: tipo?.name }
                                })
                            ]}
                            value={validation.values.estadoCivil || ''}
                            error={validation.errors?.estadoCivil}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/4 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Sexo
                    </div>
                    <div className="justify-center">
                        <CustomSelect
                            name="sexo_id"
                            options={[
                                { label: 'Seleccione una opción', value: 0 },
                                ...sexos?.map(tipo => {
                                    return { value: tipo?.id, label: tipo?.nombre }
                                })
                            ]}
                            value={validation.values.sexo_id || ''}
                            error={validation.errors?.sexo_id}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>

                {/*
    <div className="w-full md:w-1/2 px-2 mb-3">
        <div className="text-sm font-bold leading-4 text-black mb-2">
            INE <span className='text-red-600'>*</span>
        </div>
        <div className="justify-center">
            <FileInput
                format={'pdf'}
                onChange={(e) => handleFileChange(e, 'ine', 'pdf')}
            />
            <span className="text-red-600">
                {validation.errors?.ine ? 'Error en el archivo' : ''}
            </span>
        </div>
    </div>
    <div className="w-full md:w-1/2 px-2 mb-3">
        <div className="text-sm font-bold leading-4 text-black mb-2">
            RFC <span className='text-red-600'>*</span>
        </div>
        <div className="justify-center">
            <FileInput
                format={'pdf'}
                onChange={(e) => handleFileChange(e, 'rfc_file', 'pdf')}
            />
            <span className="text-red-600">
                {validation.errors?.rfc_file ? 'Error en el archivo' : ''}
            </span>
        </div>
    </div>
    */}

                <DireccionDatos handleFileChange={handleFileChange} validation={validation} />

                <div className="mt-10 w-full text-base font-bold leading-6 text-black border-b border-black border-solid">
                    Acerca de ti
                </div>
                <div className='w-full border-t border-black border-solid mb-3'></div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Descripción personal
                    </div>
                    <div className="justify-center">
                        <CustomTextArea
                            value={validation.values.descripcionPersonal || ''}
                            error={validation.errors?.descripcionPersonal}
                            rows={6}
                            placeholder='Por ejemplo: Soy una persona muy ordenada y estudiosa; también disfruto del deporte y del buen cine.'
                            name="descripcionPersonal"
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 px-2 mb-3">
                    <div className="text-sm font-bold leading-4 text-black mb-2">
                        Información que te hace único
                    </div>
                    <div className="justify-center">
                        <CustomTextArea
                            rows={6}
                            placeholder='Por ejemplo: Me gusta la naturaleza y me encanta ver los amaneceres.'
                            name="informacionUnica"
                            value={validation.values.informacionUnica || ''}
                            error={validation.errors?.informacionUnica}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>
            </div>

        </>
    );
}

export default DatoPerfil;
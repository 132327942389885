import React from 'react'

const CustomButtonSquare = ({ onClick, className, children, ...props }) => {
  const buttonClassName = `text-gray-800 py-2 px-4 rounded-xl shadow-md mr-4 mb-2 text-start ${className}`;

  return (
      <button
          onClick={onClick}
          className={buttonClassName}
          {...props}
      >
          {children}
      </button>
  )
 
}

export default CustomButtonSquare
import {
  SET_RESULTADOS,
  BACK_RESULTADOS
} from "./actionTypes"

export const set_resultados = data => {
  console.log(data,'dataRexs')
  return {
    type: SET_RESULTADOS,
    payload: data,
  }
}

export const back_resultados = status => {
  return {
    type: BACK_RESULTADOS,
    payload: status,
  }
}

import React, { useState, useEffect } from "react";
import CustomInput from "../../inputs/CustomInput";
import { getEspecialidades } from "../../../helpers/configRoutes/backend_helper";
import CustomSelect from "../../inputs/CustomSelect";

const ExperienciaAcademica = ({ validation, formacion, is_especialidad }) => {
    const [especialidadCatalogo, setEspecialidadCatalogo] = useState([]);
   
    useEffect(() => {
        console.log('oksoks')
        const getCatalogos = async () => {
            const response = await getEspecialidades({params:{especialidad_id:validation.values.estudios}})
            setEspecialidadCatalogo(response.especialidades)
        }
        getCatalogos()
    }, [validation.values.estudios]);

    const [experiencias, setExperiencias] = useState([
        { id: 1, cargo: '', actividades: '', fecha_inicio: '', fecha_inicio: '' }
    ]);

    const addExperienciaAcademica = () => {
        const newObjeto = { id: (experiencias.length + 1), institucion: '', estudios: '', fecha_inicio: '', fecha_inicio: '', formacion: formacion }
        setExperiencias([...experiencias, newObjeto])
    }
    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:max-w-full max-md:ml-0">
                <div className="w-full text-2xl font-bold text-black">
                    Formación Académica
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                {experiencias.map((exp, index) => (
                    <div className="flex flex-wrap mb-10" key={index}>
                        <div className="w-full text-xl font-bold text-black mb-8">
                            {`${index + 1} Formación general`}
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Estudios
                            </div>
                            <CustomSelect
                                name={`formacion_academica[${index}].especialidad_id`}
                                options={[
                                    { label: 'Seleccione una opción.', value: 0 },
                                    ...especialidadCatalogo.map(tipo => {
                                        return { value: tipo?.id, label: tipo?.nombre }
                                    })
                                ]}
                                value={validation.values.formacion_academica[index]?.especialidad_id || ''}
                                error={validation.errors?.formacion_academica?.[index]?.especialidad_id}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Institución
                            </div>
                            <CustomInput
                                name={`formacion_academica[${index}].institucion`}
                                value={validation.values.formacion_academica[index]?.institucion || ''}
                                error={validation.errors?.formacion_academica?.[index]?.institucion}
                                placeholder={'Descripción de institución'}
                                type={'text'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Inicio
                            </div>
                            <CustomInput
                                name={`formacion_academica[${index}].fecha_inicio`}
                                value={validation.values.formacion_academica[index]?.fecha_inicio || ''}
                                error={validation.errors?.formacion_academica?.[index]?.fecha_inicio}
                                placeholder={'Fecha de inicio'}
                                type={'date'}
                                onChange={validation.handleChange}
                            />
                        </div>
                        <div className="w-full md:w-1/2 px-2 mb-3">
                            <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                Fin
                            </div>
                            <CustomInput
                                name={`formacion_academica[${index}].fecha_fin`}
                                value={validation.values.formacion_academica[index]?.fecha_fin || ''}
                                error={validation.errors?.formacion_academica?.[index]?.fecha_fin}
                                placeholder={'Fecha de fin'}
                                type={'date'}
                                onChange={validation.handleChange}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[10px] max-md:max-w-full max-md:ml-0  font-bold">
                <div className="flex flex-row cursor-pointer w-52" onClick={() => addExperienciaAcademica()}>
                    <span className="material-symbols-outlined my-auto ">
                        add_circle
                    </span>
                    <p className='my-auto ml-2 '>{is_especialidad ? 'Agregar otra especialidad' : 'Agregar otra formación'}</p>
                </div>
            </div>


        </>
    )
}
export default ExperienciaAcademica;
import * as Yup from "yup";

export const validaCurp = () => {
    const getHomoclaveCode = (curpPart) => {
        const vowels = { A: 0, E: 1, I: 2, O: 3, U: 4 };
        const consonants = {
            B: 1, C: 2, D: 3, F: 4, G: 5, H: 6, J: 7, K: 8, L: 9, M: 10, N: 11,
            P: 12, Q: 13, R: 14, S: 15, T: 16, V: 17, W: 18, X: 19, Y: 20, Z: 21
        };
        const positionWeight = [3, 7, 11, 13, 17, 19, 23, 29];

        let total = 0;
        for (let i = 0; i < curpPart.length; i++) {
            const char = curpPart[i];
            if (vowels.hasOwnProperty(char)) {
                total += vowels[char] * positionWeight[i % 8];
            } else if (consonants.hasOwnProperty(char)) {
                total += consonants[char] * positionWeight[i % 8];
            } else {
                total += parseInt(char, 10) * positionWeight[i % 8];
            }
        }
        return total % 10;
    }

    const validaFormCurp = (value) => {
        if (!value) return true; // Si no hay valor, no se aplica esta validación
        const curp = value.toUpperCase(); // Convertir a mayúsculas
        // Validar que las letras sean válidas
        const validChars = /^[A-Z\d]{4}[0-9]{6}[A-Z\d]{6}[0-9]{2}$/;
        if (!validChars.test(curp)) return false;
        // Validar la homoclave
        /*const length = curp.length;
        const homoclave = curp.substring(length - 3, length - 1);
        const homoclaveCode = parseInt(curp.charAt(length - 1), 10);
        console.log(parseInt(curp.charAt(length - 1), 10), 'codae')
        const calculatedCode = getHomoclaveCode(curp.substring(0, length - 1));*/
        return validChars;
    }

    return Yup.string()
        .nullable()
        .matches(
            /^[A-Z]{4}\d{6}[A-Za-z\d]{6}\d{2}$/,
            'El formato de la CURP es incorrecto. Debe tener 4 letras seguidas de 6 dígitos, luego 6 caracteres alfanuméricos y finalmente 2 dígitos.'
        )
        .test('valid-curp', 'La CURP es inválida', (value) => {
            return validaFormCurp(value);
        })
}

export const validaNames = () => {
    return Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Solo se permiten letras y espacios')
        .max(50, 'Solo se permiten 50 caracteres')
        .required('Campo requerido')
}


export const validaDates = (edad = false, message = false) => {
    const today = new Date();
    const minEdad = new Date(today.getFullYear() - edad, today.getMonth(), today.getDate());

    return Yup.date()
        .max(minEdad, message || `Debes tener al menos ${edad} años`)
        .required('Campo requerido')
}

export const validaTarjeta = () => {
    const algoritmoValidacion = (numero) => {
        const regex = /^\d{16}$/;
        if (!regex.test(numero)) {
            return false;
        }
        let suma = 0;
        for (let i = 0; i < numero.length; i++) {
            let digito = parseInt(numero[i]);
            if ((i % 2) === 0) {
                digito *= 2;
                if (digito > 9) {
                    digito -= 9;
                }
            }
            suma += digito;
        }
        return (suma % 10) === 0;
    }

    return Yup.string().test('valid-tarjeta', 'La tarjeta es inválida', (value) => {
        return algoritmoValidacion(value);
    })
}

export const validaClabe = () => {
    const validaAlgoritmo = (clabe) => {
        const regex = /^\d{18}$/;

        // Verificar que la CLABE tenga exactamente 18 dígitos
        if (!regex.test(clabe)) {
            return false;
        }

        const pesos = [3, 7, 1];
        let suma = 0;

        // Calcular la suma ponderada
        for (let i = 0; i < 18; i++) {
            suma += (parseInt(clabe[i], 10) * pesos[i % 3]) % 10;
        }

        // Verificar que la suma sea divisible por 10
        return (suma % 10) === 0;
    }

    return Yup.string()
        .test('valid-clabe', 'La CLABE es inválida', function (value) {
            return validaAlgoritmo(value);
        })
        .required('Campo requerido');
}

export const validaVencimiento = () => {
    const validateExpiryDate = (value) => {
        if (!value) {
            return false;
        }

        const [month, year] = value.split('/').map(Number);

        if (!month || !year) {
            return false;
        }

        // Verificar si el mes es válido (1-12)
        if (month < 1 || month > 12) {
            return false;
        }

        // Obtener el año actual y el mes actual
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear() % 100; // Últimos dos dígitos del año

        // Verificar si la fecha es vigente
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return false;
        }

        return true;
    };

    return Yup.string()
        .required('Campo requerido')
        .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Fecha no válida')
        .test('is-valid-expiry-date', 'La fecha de vencimiento no es válida', (value) => {
            return validateExpiryDate(value);
        });
}

export const validaRfc = () => {
    const rfcRegex = /^([A-ZÑ&]{3,4})\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([A-Z\d]{2})([A\d])$/;
    return Yup.string()
        .required('Campo requerido')
        .matches(rfcRegex, 'RFC no válido')
}


export const validaNumerosTelefonos = (required) =>{
    if(required){
        return Yup.string()
        .required('Campo requerido')
        .test('valida-phone', 'El número debe tener entre 8 y 10 dígitos', (value) => {
            let numero = parseInt(value)
            if(!numero){
                //return true;
            }
            return /^\d{8,10}$/.test(numero)
        })
    }
    return Yup.string()
    .test('valida-phone', 'El número debe tener entre 8 y 10 dígitos', (value) => {
        let numero = parseInt(value)
        if(!numero)
        return true;

        return /^\d{8,10}$/.test(numero)
    })
    .nullable();
}
import React, { useState, useEffect } from "react";
import TabsAdmin from "./TabsAdmin";

const ServiciosAdmin = ({ estatus_id }) => {
    const [component, setComponent] = useState(false)
    return (
        <>
            {component ?
                <>
                    {component}
                </>
                :
                <TabsAdmin estatus_id={estatus_id} setComponent={setComponent} />
            }
        </>
    );
}

export default ServiciosAdmin;
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";


const ConfirmarBaja = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex flex-col items-start mt-[94px] ml-[19%]">
                <div className="text-[32px] mb-[18px] font-semibold w-[574px]">
                    <h2>Hemos cancelado exitosamente tu cuenta profesional con One 2 One Nurses</h2>
                </div>
                <div className="text-base">
                    <p className="mt-[20px] w-[574px]">
                        Al dar click en el botón de confirmación, cerraremos tu sesión y eliminaremos
                        tu perfil profesional de acuerdo a los <Link style={{ color: 'blue' }}>Términos y Condiciones</Link> del contrato con One 2 One Nurses.
                    </p>
                    <p className="mt-[20px] w-[574px]">
                        De igual forma, recibirás un correo electrónico con la confirmación de la cancelación de tu cuenta.
                    </p>
                    <p className="mt-[20px] w-[574px] font-semibold">
                        Esperamos con gusto la oportunidad de colaborar más adelante.
                    </p>
                </div>
                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-[85%] mt-[29px]">
                    <div className="flex justify-start space-x-2">
                        <CustomButtonOval onClick={() => navigate(`/login`)} className="flex justify-end bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">
                            Aceptar y cerrar sesión
                        </CustomButtonOval>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ConfirmarBaja;
import React from 'react'

const CustomSelect = ({ options, error='', ...props }) => {

    const ErrorMensaje = () => {
        if(error){
            return (
                <span className="text-red-600">
                    {error}
                </span>
            )
        }
        return
    }

    return (
        <div className="flex flex-col w-full py-1">
            <select
                className="block w-full py-2 px-4 rounded border bg-stone-100 focus:outline-none focus:border-o2o-aqua-hover"
                {...props}
            >
            {options.map(option => (
                <option key={option.value} value={option.value}>
                {option.label}
                </option>
            ))}
            </select>
            <ErrorMensaje/>
        </div>
    )
}

export default CustomSelect
import React, { useState, useEffect } from "react";
import { dangerToast } from "../../../helpers/Toast";
import { getHabilidades, getOfertaServicios } from "../../../helpers/configRoutes/backend_helper";

const HabilidadesOfertaServicios = ({ validation, update }) => {
    const [habilidades, setHabilidades] = useState([]);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        const getCatalogos = async () => {
            const responseHabilidades = await getHabilidades()
            const responseOS = await getOfertaServicios()
            setHabilidades(responseHabilidades.habilidades)
            setCategorias(responseOS.categorias)
        }
        getCatalogos()
    }, []);

    const agregarInteresesPersonales = (id, categoria) => {
        const interesesActuales = validation.values.servicios || [];

        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('servicios', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.categoria_id == categoria)
            console.log(categoria)
            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id, categoria_id: categoria }];
                validation.setFieldValue('servicios', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    const agregarHabilidades = (id) => {
        const interesesActuales = validation.values.habilidades || [];

        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('habilidades', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.id == id)

            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id }];
                validation.setFieldValue('habilidades', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    const datos = {}

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[40px] max-md:max-w-full max-md:ml-0">
                <div className="w-full text-base font-bold leading-6 max-md:max-w-full">
                    Habilidades profesionales
                </div>
                <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                <div className="flex flex-wrap justify-start items-center mt-6 gap-3">
                    {habilidades.map((data, index) => {
                        return (
                            <button
                                type="button"
                                key={index}
                                className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation?.values?.habilidades.length > 0 ? (validation?.values?.habilidades?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-hover' : ''):''}`}
                                onClick={() => agregarHabilidades(data?.id)}
                            >
                                {data.nombre}
                            </button>
                        )
                    })}
                </div>
            </div>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[40px] max-md:max-w-full max-md:ml-0">
                <div className="w-full text-base font-bold leading-6 max-md:max-w-full">
                    {update ?
                        '3. Oferta de servicios'
                        :
                        'Oferta de servicios'
                    }
                </div>
                {categorias.map((categoria, index) => (
                    <div className={`flex flex-col px-5 pt-3 mt-3 w-full ${update ? '' : 'border-t border-black border-solid'} max-md:max-w-full`} key={index}>
                        <div className="w-full font-bold max-md:max-w-full">
                            {categoria.nombre}
                        </div>
                        <div className={`flex flex-col px-5 pt-3 mt-3 w-full border-t border-black border-solid max-md:max-w-full`} key={index}></div>
                        <div className="flex flex-wrap justify-start items-center mt-6 gap-3">
                            {categoria.ofertas.map((data, index) => {
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation.values.servicios?.length > 0 ? (validation.values.servicios?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-hover' : ''):''}`}
                                        onClick={() => agregarInteresesPersonales(data?.id, data.categoria_id)}
                                    >
                                        {data.nombre}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>

        </>
    )

}

export default HabilidadesOfertaServicios;
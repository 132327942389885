import React from 'react'
import Buscador from '../home/Buscador'
import { useNavigate } from 'react-router-dom'
import { imgUrl } from '../../../helpers/recursosurl';
import CustomButtonOval from '../../../components/buttons/CustomButtonOval';

const CartaDirectora = () => {

    const navigate = useNavigate();

    return (
        <>
        <Buscador/>
        <div className="flex flex-col px-5 font-bold text-black">
            <div className="flex justify-center self-start item-center">
                <CustomButtonOval
                    className={'bg-o2o-gris hover:bg-o2o-aqua-hover'}
                    onClick={() => navigate('/quienes-somos')}
                >
                    Regresar a ¿Quiénes somos?
                </CustomButtonOval>
            </div>
            <div className="flex flex-col items-center self-center pb-16 mt-20 w-full rounded-2xl shadow-sm bg-stone-100 max-w-[927px] max-md:mt-10 max-md:max-w-full font">
                <img
                loading="lazy"
                srcSet={`${imgUrl}/directora-banner.svg`}
                className="self-stretch w-full aspect-[2.56] max-md:max-w-full"
                />
                <div className="mt-11 text-3xl leading-9 w-[748px] max-md:mt-10 max-md:max-w-full">
                Carta de la Directora General de One 2 One Nurses
                </div>
                <div className="mt-8 text-sm leading-4 max-md:max-w-full text-start">
                Octubre 17 de 2023
                </div>
                <div className="mt-8 leading-6 w-[748px] max-md:max-w-full px-4 sm:px-0 font-normal">
                    <span className="font-bold">
                        Estimados miembros de la comunidad de One 2 One Nurses:
                    </span>
                    <br />
                    <br />
                    <p>
                        Es un placer dirigirme a todos ustedes como Directora General de One 2
                    One Nurses, una startup dedicada a revolucionar la atención de
                    enfermería en línea. Hoy, quiero compartir con entusiasmo nuestra
                    misión clara y apasionada: Ser una marca de servicio con carácter
                    fuerte, maduro y sensible, que no solo atiende con humanidad a las
                    personas, sino que también facilita la selección de profesionales de
                    la salud y resuelve necesidades clave de interacción.
                    </p>
                    <br />
                    <br />
                    En el corazón de nuestro propósito se encuentra la profunda convicción
                    de que cada individuo merece recibir atención de alta calidad,
                    especialmente en momentos cruciales para su salud. Creemos que el
                    camino hacia una recuperación exitosa y un bienestar duradero se basa
                    en la empatía, la comprensión y el apoyo integral.
                    <br />
                    <br />
                    En One 2 One Nurses, estamos comprometidos a brindar esta atención con
                    la seriedad y el respeto que merecen todos nuestros usuarios.
                    <br />
                    <br />
                    Nuestra plataforma no es simplemente un lugar para encontrar
                    enfermeras y enfermeros altamente calificados. Es el origen de una
                    comunidad donde personas dispuestas a brindar atención se conectan con
                    aquellas que buscan atención especializada. Esta comunidad se nutre de
                    la colaboración y el compromiso mutuo para superar las adversidades
                    que afectan la salud. En One 2 One Nurses, no solo estamos creando un
                    servicio; estamos forjando relaciones de confianza y apoyo mutuo.
                    <br />
                    <br />
                    Para garantizar que nuestros usuarios tengan acceso a profesionales de
                    la más alta calidad, contamos con el respaldo de una institución con
                    mas de 15 años de experiencia; la Escuela de Enfermería Angelópolis.
                    Aquí, reclutamos a nuestro valioso recurso humano, lo que nos permite
                    inyectar innovación y profesionalización a nuestra plataforma. Sabemos
                    que la formación y la experiencia son cruciales en la atención de
                    enfermería, y trabajamos en estrecha colaboración con esta institución
                    para asegurarnos de que nuestros usuarios estén en las mejores manos
                    posibles.
                    <br />
                    <br />
                    Tomando con seriedad, formalidad y respeto, la importancia de la
                    confianza en la atención de enfermería.
                    <br />
                    <br />
                    Nuestro objetivo en One 2 One Nurses es claro: diseñar una marca de
                    servicio que atienda con humanidad, facilite la selección de
                    profesionales de la salud, resuelva necesidades clave de interacción,
                    dignifique la profesión de enfermería y construya una comunidad basada
                    en la atención y el apoyo mutuo.
                    <br />
                    <br />
                    En One 2 One Nurses, creemos en el poder de la colaboración y la
                    empatía para superar los desafíos de la salud.
                    <br />
                    <br />
                    Queremos que todos nuestros usuarios sientan que están en un entorno
                    de confianza, donde la atención a la salud se toma con la seriedad que
                    merece, pero siempre con un entusiasmo constante por mejorar y ofrecer
                    el mejor servicio posible.
                    <br />
                    <br />
                    Nos enorgullece contar con su apoyo y confianza en nuestro viaje hacia
                    una atención de enfermería excepcional y un bienestar óptimo.
                    <br />
                    <br />
                    Con gratitud y entusiasmo,
                </div>
                <div className="mt-8 text-2xl max-md:max-w-full px-4 sm:px-0">
                — Ximena Martínez de Teresa
                </div>
            </div>
            <div className="self-center mt-16 mr-16 text-3xl leading-9 text-center w-[749px] max-md:mt-10 max-md:mr-2.5 max-md:max-w-full font">
                Una forma fácil y segura de contratar cuidados de enfermería a domicilio
            </div>
            <div className="self-center mt-5 mr-16 text-base leading-6 text-center max-md:mr-2.5 max-md:max-w-full font">
                ¡Conecta con el profesional médico que deseas!
            </div>
            <div className="self-center mt-5 mr-16 text-base leading-6 text-center max-md:mr-2.5 max-md:max-w-full justify-center font">
                <CustomButtonOval
                    className={'bg-o2o-gris hover:bg-o2o-aqua-hover'}
                    onClick={() => navigate('/como-funciona')}
                >
                    ¿Cómo funciona?
                </CustomButtonOval>
                <CustomButtonOval
                    className={'bg-o2o-gris hover:bg-o2o-aqua-hover'}
                    onClick={() => navigate('/testimoniales')}
                >
                    Lee los testimoniales
                </CustomButtonOval>
            </div>
        </div>
        </>
    )
};
export default CartaDirectora;
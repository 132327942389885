import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Divider from './Divider';
import CustomButtonSquare from './buttons/CustomButtonSquare';

const dias = [
  { id: 1, nombre: 'Domingo' },
  { id: 2, nombre: 'Lunes' },
  { id: 3, nombre: 'Martes' },
  { id: 4, nombre: 'Miércoles' },
  { id: 5, nombre: 'Jueves' },
  { id: 6, nombre: 'Viernes' },
  { id: 7, nombre: 'Sábado' }
];

function getMonthCalendar(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();

  const calendar = [];

  // Rellenar los días anteriores al primer día del mes
  for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
    calendar.push({
      day: '',
      dayOfWeek: ''
    });
  }

  // Rellenar los días del mes
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i);
    const dayOfWeek = date.toLocaleDateString('es-ES', { weekday: 'short' });
    calendar.push({
      day: i,
      dayOfWeek: dayOfWeek
    });
  }

  return calendar;
}

const Calendar = ({arregloFechas, inicio, year, month, diasSeleccionados, fechaInicio, fechaFin, validation, addFecha, year_inicio, month_inicio, year_fin, month_fin }) => {
  const monthCalendar = getMonthCalendar(year, month);
  const monthName = new Date(year, month).toLocaleDateString('es-ES', { month: 'long' });
  const dayNamesAbbr = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];

  let diasMarca = dias?.map(data => {
    if (diasSeleccionados?.includes(data?.id)) {
      return { id: data?.id, nombre: data?.nombre, acronimo: data?.nombre?.substring(0, 3)?.toLowerCase() };
    }
  });

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-auto">
      <h2 className="text-sm font-bold mb-5 text-center">{monthName ? `${monthName} ${year}` : ''}</h2>
      <div className="grid grid-cols-7 gap-1 sm:gap-2 w-full max-w-md">
        {dayNamesAbbr.map((dayName, index) => (
          <div key={index} className="text-center text-xs sm:text-sm text-black font-bold">{dayName}</div>
        ))}
        {monthCalendar.map((dayInfo, index) => {
          let count = diasMarca?.filter(marca => {
            if (marca?.acronimo === dayInfo?.dayOfWeek) {
              let dia_comparacion_final = { dia: parseInt(fechaFin?.split('-')[2]), mes: parseInt(fechaFin?.split('-')[1]), year: parseInt(fechaFin?.split('-')[0]) };
              let dia_comparacion = { dia: parseInt(fechaInicio?.split('-')[2]), mes: (parseInt(fechaInicio?.split('-')[1]) - 1), year: parseInt(fechaInicio?.split('-')[0]) }

              let comparacion = (dayInfo?.day >= dia_comparacion.dia && month >= dia_comparacion.mes && year >= dia_comparacion.year) && (dayInfo?.day <= dia_comparacion_final.dia && month <= dia_comparacion_final.mes && year <= dia_comparacion_final.year) && (dayInfo?.day <= dia_comparacion_final.dia && month <= dia_comparacion_final.mes && year <= dia_comparacion_final.year)
              let diaFecha = (`${year}-${month+1 > 9 ? month+1:`0${month+1}`}-${dayInfo.day > 9 ? dayInfo.day:`0${dayInfo.day}`}`);
              
              if (arregloFechas.some(f => f.fecha === diaFecha)) {
                return marca;
              }

              if (comparacion) {
                let fechaObj = { nombre: marca.nombre, day: dayInfo.day, fecha: `${year}/${month + 1}/${dayInfo.day}` };
                
                // Llamamos a addFecha sólo si la fecha no está ya en arregloFechas
                //addFecha(fechaObj);
                //4return marca;
              }
            }
            let diaFecha = (`${year}-${month+1 > 9 ? month+1:`0${month+1}`}-${dayInfo.day > 9 ? dayInfo.day:`0${dayInfo.day}`}`);
              
            if (arregloFechas.some(f => f.fecha === diaFecha)) {
              return marca;
            }
          }).length;

          return (
            <button
              key={index}
              className={`flex text-xs sm:text-sm justify-center items-center w-6 h-6 sm:w-8 sm:h-8 ${dayInfo.day ? `text-black ${count > 0 ? 'bg-o2o-aqua rounded-full' : ''}` : 'bg-gray-100 text-gray-600'
                }`}
            >
              {dayInfo.day}
            </button>
          )
        })}
      </div>
    </div>

  );
};


const CustomCalendarBusquedas = ({ arregloFechas, setArregloFechas, fechaInicio, fechaFin, setFechaInicio, setFechaFin, diasSeleccionados, setDiasSeleccionados, validation }) => {
  const currentDate = new Date();
  //const [arregloFechas, setArregloFechas] = useState([]);

  const addFecha = (fecha) => {
    // Sólo agregar la fecha si no está ya en el arreglo
    if (!arregloFechas.some(f => f.fecha === fecha.fecha)) {
      setArregloFechas(prevFechas => [...prevFechas, fecha]);
    }
  };

  const [anioInicio, setAnioInicio] = useState(currentDate.getFullYear());
  const [anioFin, setAnioFin] = useState(currentDate.getFullYear());
  const [mesInicio, setMesInicio] = useState(currentDate.getMonth() + 1);
  const [mesFin, setMesFin] = useState(currentDate.getMonth() + 1);

  useEffect(() => {
    const inicio = fechaInicio ? new Date(fechaInicio) : null;
    const fin = fechaFin ? new Date(fechaFin) : null;
    setAnioInicio(inicio ? inicio.getFullYear() : currentDate.getFullYear());
    setAnioFin(fin ? fin.getFullYear() : currentDate.getFullYear());
    setMesInicio(inicio ? inicio.getMonth() + 1 : currentDate.getMonth());
    setMesFin(fin ? fin.getMonth() : currentDate.getMonth());
  }, [fechaInicio, fechaFin]);

  const agregarDias = (id) => {
    const diasActuales = validation.values.dias || [];
    let nuevosDias = [];
    if (diasActuales.includes(id)) {
      nuevosDias = diasActuales.filter(diaId => diaId !== id);
    } else {
      nuevosDias = [...diasActuales, id];
    }
    validation.setFieldValue('dias', nuevosDias);
  };

  return (
    <>
      <div className='flex flex-wrap p-2'>
        <div className='w-full mb-5 font-bold'>
          Elige los días que requieres servicio <span className='text-red-600'>*</span>
        </div>
        <div className='w-full md:w-2/3 mb-5 md:mb-0'>
          {dias.map((dia, index) => (
            <CustomButtonSquare
              type='button'
              key={index}
              className={`self-start text-xs hover:bg-o2o-gris-arena ${diasSeleccionados?.includes(dia.id) ? 'bg-o2o-gris-hover' : ''}`}
              onClick={() => agregarDias(dia.id)}
            >
              {dia.nombre}
            </CustomButtonSquare>
          ))}
        </div>
        <div className='w-full md:w-1/3 flex flex-col md:flex-row text-sm'>
          <CustomButtonSquare className='bg-o2o-gris-arena w-full md:w-1/2 mr-0 md:mr-2 mb-2 md:mb-0' type='button'>
            <p className='font-bold'>Inicia <span className='text-red-600'>*</span></p>
            <p>
              <input
                type="date"
                id="fechaInicio"
                name="fecha_inicio"
                className='bg-o2o-gris-arena text-xs w-full'
                value={fechaInicio}
                onChange={validation.handleChange}
              />
            </p>
          </CustomButtonSquare>
          <CustomButtonSquare type='button' className='bg-o2o-gris-arena w-full md:w-1/2'>
            <p className='font-bold'>Termina <span className='text-red-600'>*</span></p>
            <p>
              <input
                type="date"
                id="fechaFin"
                name="fecha_fin"
                className='bg-o2o-gris-arena text-xs w-full'
                value={fechaFin}
                onChange={validation.handleChange}
              />
            </p>
          </CustomButtonSquare>
        </div>
        <div className='w-full'>
          <Divider />
        </div>
      </div>

      <div className='flex flex-wrap justify-center p-2'>
        <div className='w-full lg:w-5/12 lg:mr-20 mb-5 lg:mb-0'>
          <Calendar arregloFechas={arregloFechas} addFecha={addFecha} validation={validation} inicio={true} year={anioInicio} month={mesInicio-1} year_inicio={anioInicio} month_inicio={mesInicio - 1} diasSeleccionados={diasSeleccionados} fechaInicio={fechaInicio} fechaFin={fechaFin} />
        </div>
        <div className='w-full lg:w-5/12'>
          <Calendar arregloFechas={arregloFechas} addFecha={addFecha} inicio={false} validation={validation} year={anioFin} month={(mesFin)} year_fin={anioFin} month_fin={mesFin} diasSeleccionados={diasSeleccionados} fechaInicio={fechaInicio} fechaFin={fechaFin} />
        </div>
      </div>

    </>
  );
};

export default CustomCalendarBusquedas
import { useState } from 'react';
import CustomCalendarSearch from './CustomCalendarSearch';

const SearchForm = ({setShowBuscador, showBuscador}) => {

    const [showCalendar, setShowCalendar] = useState(false);
    
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [selectDias, setSelectDias] = useState([]);
    
    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    return (
        <div className='flex justify-center item-center w-auto'>
            <div className='absolute top-2 flex items-start justify-start pt-5 pl-2 w-full md:w-[750px]'>
                <p className='text-sm'>Esta es una búsqueda rápida:</p>
            </div>
            
            <div className='flex absolute top-16 items-center justify-center w-full md:w-[750px] h-[48px]'>
                <div className="flex justify-between bg-white rounded-full w-full h-full">
                    <span onClick={()=>setShowBuscador(!showBuscador)} className="flex items-center justify-center md:justify-normal text-gray-800 font-bold bg-o2o-aqua-shearch rounded-full min-w-12 md:w-[258px]">
                        <span className="md:px-4">
                            <img src='/icons/lupa.svg' alt='icon' className='w-[13px]' />
                        </span>
                        <p className='hidden md:inline-block font-bold'>Buscar un enfermero:</p>
                    </span>
                    <input 
                        type="text" 
                        placeholder="Elige la(s) Fecha(s):" 
                        className="rounded py-1 px-3  mr-2 w-1/4 placeholder:text-black" 
                        onClick={toggleCalendar}
                    />
                    <input type="text" placeholder="Turno" className="rounded py-1 px-3 mr-2  w-1/4 placeholder:text-black" />
                    <button className="bg-o2o-aqua-ir right-0 text-black font-bold rounded-full w-[48px] h-[48px]  hover:bg-o2o-aqua-hover">Ir</button>
                </div>
            </div>
            {showCalendar && (
                <>
                <div className="absolute z-20 w-full md:w-[750px]  bg-white top-32 rounded-lg  shadow-lg">
                    <CustomCalendarSearch />
                </div>
                </>
            )}
        </div>
    );
};

export default SearchForm;


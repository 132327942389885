import React from "react";
import BaseModalAnimated from "./BaseModalAnimated";

const ConfirmModal = ({ action, modal, setModal }) => {
    return (
        <BaseModalAnimated action={action} modal={modal} setModal={setModal}>
            <div className="relative flex-auto p-1 mt-5">
                <div className="w-full px-2 mb-3 border-t border-black border-solid">
                    <div className="flex w-full px-2 mb-3 mt-3 text-center">
                        {modal.text ?? '¿Estas seguro de eliminar?'}
                    </div>
                </div>
            </div>
        </BaseModalAnimated>
    )
}

export default ConfirmModal;
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InformacionPersonal from "../../perfiles/InformacionPersonal";
import InteresesPersonales from "../../../components/registro/InteresesPersonales";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { usuariosResource } from "../../../helpers/configRoutes/backend_helper";
import { validaCurp, validaNames, validaDates, validaNumerosTelefonos } from "../../../helpers/formularios/Validation";
import { showFormErrors } from "../../../helpers/showErrors";
import { useNavigate } from "react-router-dom";

const PerfilAdministrador = ({ authUser }) => {
    const navigate = useNavigate();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: authUser,
        validationSchema: Yup.object({
            nombre: validaNames(),
            apellidos: validaNames(),
            fechaNacimiento: validaDates(18),
            movil: validaNumerosTelefonos(true),
            email: Yup.string()
                .email('Correo electrónico inválido')
                .required('Campo requerido'),
            curp: validaCurp(),
            calle: Yup.string().required('Campo requerido'),
            cp: Yup.string()
                .required('Campo requerido'),
            cp_id: Yup.string()
                .required('Campo requerido'),
            numeroExterior: Yup.string().required('Campo requerido'),
            numeroInterior: Yup.string().required('Campo requerido, En caso de no contar con Numero Interior poner lo mismo que numero exterior.'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            descripcionPersonal: Yup.string().nullable(),
            informacionUnica: Yup.string().nullable(),
            sexo_id: Yup.string().required('Campo requerido'),
            perfilImg: Yup.string().required('Campo requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await usuariosResource('put', values, values.id);

            if (response.status) {
                let storedData = localStorage.getItem('authUser');
                if (storedData) {
                    let userData = JSON.parse(storedData);
                    userData.user = response.item;
                    localStorage.setItem('authUser', JSON.stringify(userData));
                }
            } else {
                // Manejo de errores
            }
        },
    });



    return (
        <>
            <form onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                showFormErrors(validation)
                return false;
            }} className="mr-4 sm:mr-6 md:mr-8 lg:mr-12 xl:mr-16">
                <InformacionPersonal validation={validation} />
                <div className="ml-4 sm:ml-6 md:ml-8 lg:ml-12 xl:ml-16">
                    <InteresesPersonales validation={validation} categorias_ids={1} />
                </div>
                <div className="border-t border-gray-300 ml-4 sm:ml-6 md:ml-8 lg:ml-10 xl:ml-16 my-4"></div>
                <div className="bg-o2o-gris-calido flex justify-center items-center w-full sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto rounded-lg p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 mt-12">
                    <div className="flex">
                        <CustomButtonOval
                            type={'success'}
                            className='bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover'
                        >
                            Guardar información
                        </CustomButtonOval>
                        <CustomButtonOval
                            type={'button'}
                            onClick={()=>navigate('/home')}
                            className='bg-o2o-gris-arena text-sm hover:bg-o2o-gris-arena-hover'
                        >
                            Cancelar
                        </CustomButtonOval>
                    </div>
                </div>
            </form>

        </>
    );
};

export default PerfilAdministrador;
